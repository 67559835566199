import { makeStyles, createStyles } from '@material-ui/core/styles';

const HLSLivestreamStyles = makeStyles((theme: any) => createStyles({
  linkTextField: {
    width: 536,
    position: "relative",
    top: 50,
    left: 0,
    float: "left",
    marginBottom: 130,
  },
  updateButton: {
    backgroundColor: "#777777",
    position: "absolute",
    left: 0,
    top: 140,
    color: "white",
    padding: 0,
    width: 122,
    height: 44,
  },
  hlsHeader: {
    color: "#FFCE00",
    fontSize: 14,
    fontWeight: "bold",
    position: "absolute",
    top: 5,
    left: 0
  },
  bridgeSyncToggle: {
    position: "absolute",
    left: 200,
    top: 142
  },
  bridgeSyncToggleSwitch: {
    color: "#FF0092"
  }
})
)

export { HLSLivestreamStyles };