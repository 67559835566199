import { makeStyles, createStyles } from '@material-ui/core/styles';

const CurrentPresenterToolStyles = makeStyles((theme: any) => createStyles({
  currentPresenterToolHolder: {
    width: 812,
    height: "auto",
    minHeight: 412,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    position: "absolute",
    top: 10,
    right: 0,
    [theme.breakpoints.down('md')]: {
      width: 625,
    }
  }
})
)

export { CurrentPresenterToolStyles };