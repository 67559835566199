import { makeStyles, createStyles } from '@material-ui/core/styles';

const PresentersWebcamStyles = makeStyles((theme: any) => createStyles({
  header: {
    color: "#FFCE00",
    fontSize: 14,
    fontWeight: "bold",
    position: "absolute",
    top: 6,
  },
  presentersWebcamHeader: {
    left: 0,
  },
  webcamHeader: {
    right: 10,
  },
  refreshButton: {
    position: "absolute",
    top: -5,
    left: 130,
    padding: 6,
  }
})
)

export { PresentersWebcamStyles };