import React, { useState, useEffect, useRef } from 'react';

export const PresenterToolsContext = React.createContext(null);

export const PresenterToolsContextProvider = (props) => {

  const [spacePresentations, setSpacePresentations] = useState([]);

  const [ bridgeSync, toggleBridgeSync ] = useState(false);

  const [contextHLSLink, setContextHLSLink] = useState("");

  const presenterToolsContextValue = {
    spacePresentations,
    setSpacePresentations,
    contextHLSLink,
    setContextHLSLink,
    bridgeSync,
    toggleBridgeSync
  }

  return (
    <PresenterToolsContext.Provider value={presenterToolsContextValue}>
      {props.children}
    </PresenterToolsContext.Provider>
  )
}
