import classes from '*.module.css';
import { Dialog, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import styles from './ErrorPopup.module.css';
import React, { ReactElement, useContext } from 'react';
import { ErrorPopupStyles } from './ErrorPopopStyles';
import { ErrorContext } from '../../../../Context/ErrorContext';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

interface Props {
  handlePopupClose: () => void,
}

export default function ErrorPopup(props: Props): ReactElement {

  const {
    errorMessages,
    setErrorMessages
  } = useContext(ErrorContext);

  //Popup close
  const handleCloseIconClick = () => {
    props.handlePopupClose();
  }

  //Error close
  const handleErrorClose = (errorMessage: string) => {
    setErrorMessages(errorMessages.filter(function (err) {
      return (err !== errorMessage);
    }))
  }

  let errorID = 0;

  const classes = ErrorPopupStyles();

  return (
    <Dialog
      className={classes.errorPopup}
      open={true}
    >
      <div className={classes.popup}>

        <IconButton
          className={classes.closeButton}
          onClick={handleCloseIconClick}
        >
          <CloseIcon />
        </IconButton>

        <Typography variant="h2" className={classes.errorsHeader}>
          ERRORS
        </Typography>

        <div className={styles.errorsHolder}>
          {errorMessages.map(error => {
            errorID++;
            return (
              <ErrorMessage
                key={errorID}
                errorStack={false}
                handleErrorClose={handleErrorClose}
                errorMessage={error}
              />
            )
          })}
        </div>
      </div>
    </Dialog>
  )
}
