import { makeStyles, createStyles } from '@material-ui/core/styles';

const StreamCenterStyles = makeStyles((theme: any) => createStyles({
  background: {
    backgroundColor: theme.palette.intakePaper.main
  },
  loadingSpinner: {
    color: "white",
    width: "80px !important",
    height: "80px !important",
    position: "absolute",
    left: "50%",
    marginLeft: -40,
    top: 300,
    zIndex: 999
  }
})
)

export { StreamCenterStyles };