import { Button, IconButton, Paper, Slider, Typography } from '@material-ui/core';
import React, { ReactElement, useContext, useState, useEffect } from 'react';
import { JumbotronContext } from "../../../Context/JumbotronContext";
import { ServerHelper } from '../../../utilities/ServerHelper';
import LiveIcon from '@material-ui/icons/FiberManualRecord';
import SettingsIcon from '@material-ui/icons/Settings';
import PlayIcon from '@material-ui/icons/PlayArrow';
import VolumeMuteIcon from '@material-ui/icons/VolumeMute';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import StopIcon from '@material-ui/icons/Stop';
import styles from './JumbotronDisplay.module.css';
import { JumbotronDisplayStyles } from './JumbotronDisplayStyles';
import LiveJumbotron from './LiveJumbotron/LiveJumbotron';
import PreviewJumbotron from './PreviewJumbotron/PreviewJumbotron';
import SettingsPopup from './SettingsPopup/SettingsPopup';
import { PresenterToolsContext } from '../../../Context/PresenterToolsContext';

interface Props {

}

export default function JumbotronDisplay(props: Props): ReactElement {

  const {
    currentPreviewType,
    currentPreviewSlideIndex,
    setCurrentPreviewSlideIndex,
    setCurrentSlideURL,
    disableMakePreviewLive,
    currentPresentation,
    isLive,
    toggleIsLive,
    currentLiveType,
    setCurrentLiveType,
    setCurrentPreviewType,
    currentLivestreamURL,
    hasSetScreenshareVariable,
    toggleHasSetScreenshareVariable
  } = useContext(JumbotronContext);

  const {
    bridgeSync,
    toggleBridgeSync,
    contextHLSLink,
  } = useContext(PresenterToolsContext);

  const [liveVolume, setLiveVolume] = useState(50);

  const [settingsPopup, toggleSettingsPopup] = useState(false);

  useEffect(() => {

    //Add listener for EventVariableUpdate 
    SHOWBOAT.SocketIOController.OnEventVariableUpdate.Add("PRESENTER#Presentation", handleEventVariableUpdate);

    return function cleanup() {
      SHOWBOAT.SocketIOController.OnEventVariableUpdate.Remove("PRESENTER#Presentation", handleEventVariableUpdate);
    }
  }, [])

  //Click handler for "make preview live"
  const handleMakePreviewLive = () => {

    //Raise SHOWBOAT.VirtualScreenController with correct event, corresponding to what is shown in preview
    //Use current preview type from context

    console.log("Making preview type live", currentPreviewType);

    //Set live type in context
    setCurrentLiveType(currentPreviewType);

    //Clear the live canvas
    ServerHelper.LiveJumbotronBroadcastMediaStream.clear();

    //Copy preview to live
    ServerHelper.PreviewJumbotronBroadcastMediaStream.copyLayersTo(
      ServerHelper.LiveJumbotronBroadcastMediaStream,
      false
    );

    //Set src on test video
    /* (document.getElementById("testVideo") as HTMLVideoElement).srcObject = ServerHelper.LiveJumbotronBroadcastMediaStream.getMediaStream();
    (document.getElementById("testVideo") as HTMLVideoElement).play(); */

    //If we are livestreaming to the room, send the correct version of the server variable
    if (isLive) {
      
      //If making an HLS stream live without bridge sync, send hls variable
      if (currentPreviewType === "hls" && !bridgeSync) {
        toggleHasSetScreenshareVariable(false);

        console.log("SENDING MODE: livestream");

        SHOWBOAT.SocketIOController.SetServerEventVariable('PRESENTER#Presentation', {
          mode: "livestream",
          url: contextHLSLink,
          userID: SHOWBOAT.LocalAvatarDataManager.avatarData.userID
        });
      } else {
        console.log("B");
        //Otherwise, send normal stream variable

        if (!hasSetScreenshareVariable) {
          console.log("SENDING MODE: screenshare");

          toggleHasSetScreenshareVariable(true);

          SHOWBOAT.SocketIOController.SetServerEventVariable("PRESENTER#Presentation", {
            mode: "screenshare",
            userID: SHOWBOAT.LocalAvatarDataManager.avatarData.userID,
            screenshareID: ServerHelper.screenshareID
          });
     
        }
        
      }
    }


    //Move video to live jumbotron and clone back to preview if dealing with a localvideo
    if (currentPreviewType === "localvideo") {
      console.log("J");
      handleMakeLocalVideoLive();
    } else {
      //Remove any video elements from live jumbotron
      let liveVideo = document.getElementById("localVideoLive");
      if (liveVideo !== null) liveVideo.parentNode.removeChild(liveVideo);
    } 
    
    //If we are making a slide live, advance selectedSlide and slide shown on preview

    if (currentPreviewType === "slides") {
      if (!((currentPreviewSlideIndex + 1) > (currentPresentation.slides.length - 1))) {
        //Show next slide on preview jumbotron
        let nextImgElement = document.createElement("img");
        nextImgElement.src = currentPresentation.slideRoot + currentPresentation.slides[currentPreviewSlideIndex + 1];
        nextImgElement.id = "nextSlide";
        nextImgElement.crossOrigin = "anonymous";

        ServerHelper.PreviewJumbotronBroadcastMediaStream.addDrawableElement(
          "nextSlide",
          nextImgElement,
          true,
          true,
          0,
          0,
          960,
          540
        );

        setCurrentPreviewSlideIndex(currentPreviewSlideIndex + 1);
        setCurrentSlideURL(currentPresentation.slideRoot + currentPresentation.slides[currentPreviewSlideIndex + 1])
      }
    }

  }

  const handleMakeLocalVideoLive = () => {
    let liveJumbotron = document.getElementById("liveJumbotron");
    let liveVideo = document.getElementById("localVideoLive");
    let previewJumbotron = document.getElementById("previewJumbotron");
    let previewVideo = document.getElementById("localVideoPreview") as HTMLVideoElement;

    previewVideo.id = "localVideoLive";

    let playheadPosition = previewVideo.currentTime;
    let paused = previewVideo.paused;

    //Remove preview video from preview if it is there
    if (previewVideo !== null) {
      previewVideo.parentNode.removeChild(previewVideo);
    }

    //If we have a local video in live already, remove it
    if (liveVideo !== null) liveVideo.parentNode.removeChild(liveVideo);

    //Append preview video to live jumbotron
    if (liveJumbotron !== null && previewVideo !== null) liveJumbotron.appendChild(previewVideo);

    //Append new preview video to preview jumbotron
    let newVideoNode = document.createElement("video") as HTMLVideoElement;
    newVideoNode.controls = true;
    newVideoNode.id = "localVideoPreview";
    newVideoNode.currentTime = playheadPosition;
    newVideoNode.src = previewVideo.src;
    newVideoNode.crossOrigin = "anonymous";
    if (!paused) {
      newVideoNode.play();
    }

    if (previewJumbotron !== null) {

      //Append new video to preview jumbotron
      previewJumbotron.appendChild(newVideoNode);

      ServerHelper.PreviewJumbotronBroadcastMediaStream.addVideoElement(
        "localVideoPreview",
        newVideoNode,
        true,
        true,
        0,
        0,
        960,
        540
      );
    }
    
  }

  //Settings button click
  const handleSettingsButtonClick = () => {
    toggleSettingsPopup(!settingsPopup);
  }

  //Settings popup close
  const handleSettingsPopupClose = () => {
    toggleSettingsPopup(false);
  }

  //Play button click
  const handlePlayButtonClick = async () => {
    //TOGGLE ISLIVE IN CONTEXT

    //If we are going live, start the stream 
    let toggleState = !isLive;

    if (toggleState) {

      let startStream = await SHOWBOAT.LiveswitchMediaUpstreamController.startMediaStreamConnection(
        ServerHelper.LiveJumbotronBroadcastMediaStream.getMediaStream(),
        "Media_" + SHOWBOAT.LocalAvatarDataManager.avatarData.userID + "_" + ServerHelper.screenshareID,
        true,
        true
      );

      if (startStream) {

        if ((currentPreviewType === "hls" && !bridgeSync)) {

          console.log("SENDING MODE: livestream");

          //Making HLS Live without bridge sync - requires its own server variable
          SHOWBOAT.SocketIOController.SetServerEventVariable('PRESENTER#Presentation', {
            mode: "livestream",
            url: currentLivestreamURL,
            userID: SHOWBOAT.LocalAvatarDataManager.avatarData.userID
          });

        } else {

          console.log("SENDING MODE: screenshare");

          //Send livestream over to server
          SHOWBOAT.SocketIOController.SetServerEventVariable("PRESENTER#Presentation", {
            mode: "screenshare",
            userID: SHOWBOAT.LocalAvatarDataManager.avatarData.userID,
            screenshareID: ServerHelper.screenshareID
          });

          toggleHasSetScreenshareVariable(true);

        }
       
        //Show stop button
        toggleIsLive(true);

      } else {
        //Show error, make sure we show play button
        SHOWBOAT.UIEventManager.OnUIError.Raise("Error starting livestream.")

        toggleIsLive(false);
      }
    } else {
      //Otherwise, show off screen in the room (stopping stream)
      SHOWBOAT.SocketIOController.SetServerEventVariable("PRESENTER#Presentation", {
        mode: "off",
        userID: SHOWBOAT.LocalAvatarDataManager.avatarData.userID
      });

      //Increment screenshareID
      ServerHelper.screenshareID++;

      toggleHasSetScreenshareVariable(false);
    }

    toggleIsLive(toggleState);
  }

  //Live volume change handler
  const handleVolumeChange = (e: any, newValue: number) => {

    //Set volume on stream
    ServerHelper.LiveJumbotronBroadcastMediaStream.setVolume(newValue / 50);

    setLiveVolume(newValue);
  }

  //Handler for EventVariableUpdate
  const handleEventVariableUpdate = (newValue: any) => {

    //If userID is our own, do nothing
    if (newValue.userID === SHOWBOAT.LocalAvatarDataManager.avatarData.userID) {
      return;
    } else {
      //Otherwise, we are no longer live
      toggleIsLive(false);
    }
  }

  const classes = JumbotronDisplayStyles();

  return (

    <React.Fragment>
      <Paper className={classes.jumbotronDisplayHolder}>

        <div className={styles.previewHolder}>

          <Paper className={`${classes.banner} ${classes.previewBanner}`}>
            <Typography variant="h2" className={classes.jumbotronHeader}>
              PREVIEW
            </Typography>
          </Paper>

          <PreviewJumbotron />
        </div>

        <div className={styles.liveHolder}>
          <Paper className={`${classes.banner} ${classes.liveBanner}`}>
            <LiveIcon className={classes.liveIcon}></LiveIcon>

            <Typography variant="h2" className={`${classes.jumbotronHeader} ${classes.jumbotronHeaderLive}`}>
              LIVE
            </Typography>

            <IconButton className={classes.settingsIconButton} onClick={handleSettingsButtonClick}>
              <SettingsIcon className={classes.settingsIcon} />
            </IconButton>

            <Button
              variant="contained"
              className={isLive
                ? `${classes.playButton} ${classes.playButtonLive}`
                : classes.playButton
              }
              onClick={handlePlayButtonClick}
            >
              {isLive
                ? <StopIcon className={classes.stopIcon} />
                : <PlayIcon className={classes.playIcon} />
              }

            </Button>
          </Paper>

          <LiveJumbotron />
        </div>

        <Paper className={classes.volumeHolder}>

          <VolumeMuteIcon className={classes.volumeMuteIcon} />

          <Slider
            classes={{
              root: classes.volumeSlider,
              rail: classes.volumeSliderRail,
              track: classes.volumeSliderTrack
            }}
            value={liveVolume}
            onChange={handleVolumeChange}
          />

          <VolumeUpIcon className={classes.volumeUpIcon} />

        </Paper>

        <Button
          className={classes.makeLiveButton}
          variant="contained"
          onClick={handleMakePreviewLive}
          disabled={currentPreviewType === "blank"}
        >
          MAKE PREVIEW LIVE
        </Button>

      </Paper>

      {settingsPopup &&
        <SettingsPopup
          handlePopupClose={handleSettingsPopupClose}
        />
      }
    </React.Fragment>
  )
}
