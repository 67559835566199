import React, { ReactElement, useState, useContext, useEffect } from 'react';
import { Button, IconButton, Paper, Typography } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ErrorIcon from '@material-ui/icons/Warning';
import styles from "./Main.module.css";
import { MainStyles } from './MainStyles';
import JumbotronDisplay from '../../components/StreamCenter/JumbotronDisplay/JumbotronDisplay';
import PresenterTools from '../../components/StreamCenter/PresenterTools/PresenterTools';
import ShowboatBridge from '../../components/ShowboatBridge/ShowboatBridge';
import StreamCenter from '../../components/StreamCenter/StreamCenter';
import { ErrorContext } from '../../Context/ErrorContext';
import ErrorPopup from '../../components/StreamCenter/Errors/ErrorPopup/ErrorPopup';

interface Props {

}

export default function Main(props: Props): ReactElement {

  const {
    errorMessages
  } = useContext(ErrorContext);

  const [selectedPage, setSelectedPage] = useState("home");

  const [errorPopup, toggleErrorPopup] = useState(false);

  const handleErrorIconClick = () => {
    toggleErrorPopup(!errorPopup);
  };

  const handleErrorTestClick = () => {
    SHOWBOAT.UIEventManager.OnUIError.Raise("Error connecting to liveswitch. Attempting to reconnect.")
  }

  const handleErrorTestClick2 = () => {
    SHOWBOAT.UIEventManager.OnUIError.Raise("Error starting virtual camera.")
  }


  console.log("AAAA", errorMessages.length);

  useEffect(() => {
    console.log("ERROR MESSAGES", errorMessages);
  }, [errorMessages]);

  //Error popup close
  const handleErrorPopupClose = () => {
    toggleErrorPopup(false);
  }

  const classes = MainStyles();

  return (
    <React.Fragment>
      <Paper square className={classes.mainWrapper}>
        <div className={styles.mainContentHolder}>

        {/*   <Button
            variant="contained"
            color="primary"
            className={classes.errorTestButton}
            onClick={handleErrorTestClick}
          >
            TEST ERROR
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.errorTestButton2}
            onClick={handleErrorTestClick2}
          >
            TEST ERROR2
          </Button> */}

          <div className={styles.bookingNameHolder}>

            <img className={styles.sbLogo} alt="Showboat logo" src="assets/images/sb-logo.png" />

            <Typography variant="h1" className={classes.bookingName}>
              {SHOWBOAT.ApplicationSkin.eventName}
            </Typography>

            {/* Show error icon if we there are error messages */}
            {(errorMessages.length > 0) &&
              <IconButton
                className={classes.errorButton}
                onClick={handleErrorIconClick}
              >
                <ErrorIcon />
              </IconButton>
            }

            {/* Show Home icon if we are not on homepage */}
            {selectedPage !== "home" &&
              <IconButton
                className={classes.homeButton}
                onClick={() => window.location.reload()}
              >
                <HomeIcon />
              </IconButton>
            }

          </div>

          {
            {
              "home": <ShowboatBridge setSelectedPage={setSelectedPage} />,
              "streamCenter":
                <>
                  <StreamCenter />
                </>
            }[selectedPage]
          }


        </div>
      </Paper>

      {errorPopup &&
        <ErrorPopup
          handlePopupClose={handleErrorPopupClose}
        />
      }
    </React.Fragment>

  )
}
