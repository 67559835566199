import classes from '*.module.css';
import { Button } from '@material-ui/core';
import React, { ReactElement, useContext, useEffect } from 'react';
import { JumbotronContext } from "../../../../Context/JumbotronContext";
import { ServerHelper } from '../../../../utilities/ServerHelper';
import { DefaultScreenStyles } from './DefaultScreenStyles';

interface Props {

}

export default function DefaultScreen(props: Props): ReactElement {

  const {
    setCurrentPreviewType,
    setCurrentLiveType,
  } = useContext(JumbotronContext);

  useEffect(() => {

    //Change current type in context
    setCurrentPreviewType("default");

    if (SHOWBOAT.ApplicationSkin.worldSkin.jumboTronDefaultImage.type === "Video") {
      //Show default video on jumbotron
      let defaultVideo = document.createElement("video") as HTMLVideoElement;

      defaultVideo.id = "defaultVideo";
      defaultVideo.src = SHOWBOAT.ApplicationSkin.worldSkin.jumboTronDefaultImage.value;
      defaultVideo.crossOrigin = "anonymous";

      //Play and loop the video
      defaultVideo.play();
      defaultVideo.loop = true;

      ServerHelper.PreviewJumbotronBroadcastMediaStream.addVideoElement(
        "defaultVideo",
        defaultVideo,
        true,
        true,
        0,
        0
      )

    } else if (SHOWBOAT.ApplicationSkin.worldSkin.jumboTronDefaultImage.type === "Image") {
      //Show default image on jumbotron
      let defaultImg = document.createElement("img") as HTMLImageElement;
      defaultImg.id = "defaultImg";
      defaultImg.src = SHOWBOAT.ApplicationSkin.worldSkin.jumboTronDefaultImage.value;
      defaultImg.crossOrigin = "anonymous";

      ServerHelper.PreviewJumbotronBroadcastMediaStream.addDrawableElement(
        "defaultImg",
        defaultImg,
        true,
        true,
        0,
        0,
        960,
        540
      )
    }
    else {
      //TODO: TODO: TODO: TODO:
      //Handle other asset types for default
      //do nothing for now
    }

  }, []);

  const classes = DefaultScreenStyles();

  return (
    <React.Fragment>

    </React.Fragment>
  )
}
