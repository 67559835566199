import { Radio, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';

import styles from "./PresenterItem.module.css";
import { PresenterItemStyles } from './PresenterItemStyles';

interface Props {
  userID: string,
  cameraEnabled: boolean,
  firstName: string,
  lastName: string,
  selectedPresenterID: string,
  setSelectedPresenterID: (presenterID: string) => void,
}

export default function PresenterItem(props: Props): ReactElement {

  //Check webcam
  const handleCheckWebcam = () => {

    //We are un-checking
    if (props.selectedPresenterID === props.userID) {
      props.setSelectedPresenterID("");
    } else {
      //We are checking
      props.setSelectedPresenterID(props.userID);
    }
  }

  const classes = PresenterItemStyles();

  return (
    <div className={styles.presenterItemHolder}>

      <Typography variant="body1" className={classes.presenterName}>
        {props.firstName} {props.lastName}
      </Typography>

      <Radio
        classes={{
          root: classes.radio,
          checked: classes.radioChecked,
          disabled: classes.radioDisabled
        }}
        checked={props.selectedPresenterID === props.userID}
        disabled={!props.cameraEnabled}
        onClick={handleCheckWebcam}
      />

    </div>
  )
}
