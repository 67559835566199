import { makeStyles, createStyles } from '@material-ui/core/styles';

const PresenterToolsSelectionStyles = makeStyles((theme: any) => createStyles({
  showboatConnectHeader: {
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "Roboto, sans-serif",
    color: "#FF0092"
  },
  presenterToolButton: {
    textAlign: "left",
    paddingTop: 5,
    paddingBottom: 5,
    position: "relative",
    top: 10,
  },
  presenterToolButtonLabel: {
    textAlign: "left",
    display: "inline-block",
    position: "relative",
    right: 0,
  },
  presenterToolButtonLabelSelected: {
    color: "#FF0092",
    fontWeight: "bold"
  },
  presenterToolsSelectionWrapper: {
    width: 260,
    height: 412,
    position: "absolute",
    left: 0,
    top: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    textAlign: "left",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10
  },
  sourcesHeader: {
    color: "#FFCE00",
    fontSize: 14,
    fontFamily: "Roboto, sans-serif",
    position: "absolute",
    left: 34,
    top: 30,
    fontWeight: "bold"
  }
})
)

export { PresenterToolsSelectionStyles };