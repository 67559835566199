import { Button, FormControlLabel, Switch, TextField, Typography } from '@material-ui/core';
import React, { ReactElement, useState, useContext, useEffect } from 'react'
import { JumbotronContext } from '../../../../Context/JumbotronContext';
import { PresenterToolsContext } from '../../../../Context/PresenterToolsContext';
import { ServerHelper } from '../../../../utilities/ServerHelper';
import { HLSLivestreamStyles } from './HLSLivestreamStyles'

interface Props {

}

export default function HLSLivestream(props: Props): ReactElement {

  const {
    setCurrentPreviewType,
    setCurrentLivestreamURL,
    setBlankMessage,
  } = useContext(JumbotronContext);

  const {
    contextHLSLink,
    setContextHLSLink,
    bridgeSync,
    toggleBridgeSync
  } = useContext(PresenterToolsContext);

  const [hlsLivestreamLink, setHLSLivestreamLink] = useState(contextHLSLink !== ""
    ? contextHLSLink
    : "");

  const classes = HLSLivestreamStyles();

  const [ livestreamRequiredError, toggleLivestreamRequiredError ] = useState(false);

  useEffect(() => {

    //Show reset on mount in preview
    setCurrentPreviewType("blank");

    setBlankMessage("Please enter a livestream URL");

    ServerHelper.PreviewJumbotronBroadcastMediaStream.clear();
  }, []);

  //HLS livestream change handler
  const handleHLSLivestreamChange = (e) => {
    setHLSLivestreamLink(e.target.value);
  }

  //Update button handler
  const handleSubmitHLSStream = async () => {

    if (!hlsLivestreamLink.trim().length) {
      toggleLivestreamRequiredError(true);
      return;
    }

    toggleLivestreamRequiredError(false);

    console.log("HLS LINK", hlsLivestreamLink);

    //Set hls link value in context
    setContextHLSLink(hlsLivestreamLink);

    const startLivestream = await SHOWBOAT.LivestreamController.startLiveStream(hlsLivestreamLink);

    //Make sure HLS promise resolves before doing anything else
    if (startLivestream) {

      let livestreamVideo = SHOWBOAT.LivestreamController.getHTMLVideoElement();

      livestreamVideo.id = "presenterToolVideo";

      //Set preview type
      setCurrentPreviewType("hls");

      //Set livestream URL in context
      setCurrentLivestreamURL(hlsLivestreamLink);

      //Send livestream video to Preview jumbotron
      ServerHelper.PreviewJumbotronBroadcastMediaStream.addVideoElement(
        "presenterToolVideo",
        livestreamVideo,
        true,
        true,
        0,
        0,
        livestreamVideo.videoWidth,
        livestreamVideo.videoHeight
      );

    } else {

      SHOWBOAT.UIEventManager.OnUIError.Raise("Error starting HLS livestream.");
    }
  }

  //Bridge sync toggle
  const handleToggleBridgeSync = () => {
    toggleBridgeSync(!bridgeSync);
  }

  return (
    <React.Fragment>

      <Typography variant="h2" className={classes.hlsHeader}>
        HLS LIVESTREAM
      </Typography>

      <TextField
        variant="outlined"
        className={classes.linkTextField}
        onChange={handleHLSLivestreamChange}
        value={hlsLivestreamLink}
        placeholder="HLS Livestream Link"
        error={livestreamRequiredError}
      />

      <FormControlLabel
        className={classes.bridgeSyncToggle}
        control={<Switch color="secondary" checked={bridgeSync} onChange={handleToggleBridgeSync} name="bridgeSync" />}
        label="Bridge Sync"
      />

      <Button
        className={classes.updateButton}
        onClick={handleSubmitHLSStream}
        variant="contained"
      >
        UPDATE
      </Button>

    </React.Fragment>
  )
}
