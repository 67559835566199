import { makeStyles, createStyles } from '@material-ui/core/styles';

const ShowboatConnectStyles = makeStyles((theme: any) => createStyles({
  linkTextField: {
    width: 536,
    position: "absolute",
    top: 0,
    left: 0,
  },
  startButton: {
    backgroundColor: "#5EB904",
    position: "absolute",
    left: 0,
    bottom: 0,
    color: "white",
    padding: 0,
    width: 107,
    height: 50,
  },
  stopButton: {
    backgroundColor: "#5EB904",
    position: "absolute",
    left: 140,
    bottom: 0,
    color: "white",
    padding: 0,
    width: 107,
    height: 50,
  },
  selectCameraHeader: {
    fontSize: 22,
    fontWeight: 400,
    position: "absolute",
    top: 45,
    left: 0,
  },
  selectMicHeader: {
    fontSize: 22,
    fontWeight: 400,
    position: "absolute",
    top: 145,
    right: 250,
    [theme.breakpoints.down('md')]: {
      right: 163,
    }
  },
  deviceErrorMsg: {
    fontSize: 12,
    [theme.breakpoints.down('sm')]: {
      width: 300,
    }
  },
  cameraSelectHolder: {
    position: "absolute",
    top: 85,
    left: 0,
    width: 355,
    textAlign: "left",
    [theme.breakpoints.down('md')]: {
      width: 265,
    }
  },
  resolutionSelectHolder: {
    position: "absolute",
    top: 85,
    right: 0,
    width: 365,
    textAlign: "left",
    [theme.breakpoints.down('md')]: {
      width: 265,
      right: 10,
    }
  },
  fpsSelectHolder: {
    position: "absolute",
    top: 185,
    left: 0,
    width: 355,
    textAlign: "left",
    [theme.breakpoints.down('md')]: {
      width: 265,
    }
  },
  micSelectHolder: {
    position: "absolute",
    top: 185,
    right: 79,
    width: 285,
    textAlign: "left",
    [theme.breakpoints.down('md')]: {
      width: 190,
      right: 84
    }
  },
  muteMicButton: {
    borderRadius: "52%",
    height: 63,
    width: 52,
    backgroundColor: theme.palette.primary.main,
    position: "absolute",
    right: 0,
    top: 150,
    [theme.breakpoints.down('md')]: {
      right: 7,
    }

  },
  micIcon: {
    fontSize: 35
  },
  fpsHeader: {
    position: "absolute",
    fontSize: 22,
    fontWeight: 400,
    left: 0,
    top: 145,
  },
  resolutionHeader: {
    position: "absolute",
    fontSize: 22,
    fontWeight: 400,
    right: 262,
    top: 45,
    [theme.breakpoints.down('md')]: {
      right: 173,
    }
  },
  virtualCameraHeader: {
    color: "#FFCE00",
    fontWeight: 500,
    position: "absolute",
    top: 5,
    left: 0,
    fontSize: 14
  },
  permissionsButton: {
    position: "absolute",
    left: 150,
    top: -5,
  }
})
)

export { ShowboatConnectStyles };