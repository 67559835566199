import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ServerHelper } from './utilities/ServerHelper';
import TimeoutErrorPage from './Pages/TimeoutErrorPage/TimeoutErrorPage';
import FailedLoginErrorPage from './Pages/FailedLoginErrorPage/FailedLoginErrorPage';

//Change document title
document.title = "Showboat Bridge";

//Timeout handler 
const handleConnectionTimeout = () => {

  ServerHelper.OnServerConnectionTimeout.Remove(handleConnectionTimeout);

  ReactDOM.render(
    <TimeoutErrorPage failCopy="" />,
    document.getElementById('root')
  )
};

//Login
const handleLoginResultSuccess = (loginResult) => {

  ServerHelper.OnServerConnectionTimeout.Remove(handleConnectionTimeout);

  console.log("THEME", SHOWBOAT.ApplicationSkin.theme)

  ReactDOM.render(
    <App
      primaryThemeColor={SHOWBOAT.ApplicationSkin.primaryThemeColor}
      theme={SHOWBOAT.ApplicationSkin.theme}
    />,
    document.getElementById('root')
  );

}

//Login fail handler
const handleLoginResultFailure = () => {
  ServerHelper.OnServerConnectionTimeout.Remove(handleConnectionTimeout);

  ReactDOM.render(
    <FailedLoginErrorPage />,
    document.getElementById('root')
  )
}

ServerHelper.Login()
  .then(async (loginResult) => {
    console.log("LOGIN RESULT", loginResult);

    //Make sure we are a presenter 
    if (loginResult.role === "presenter") {
      if (loginResult && loginResult.success) {

        handleLoginResultSuccess(loginResult);

      } else if (!loginResult.success &&
        (loginResult.failReason === "NotStarted" || loginResult.failReason === "Ended"
          || loginResult.failReason === "DeletedLoginCode" || loginResult.failReason === "CapacityFull")) {
        handleLoginResultSuccess(loginResult);
      } else {
        handleLoginResultFailure();
      }
    }
    else {
      handleLoginResultFailure();
    }

  })
  .catch(err => {
    console.log("ERROR", err);
    handleLoginResultFailure();
  })




