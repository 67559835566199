import React, { useState, useEffect, useRef } from 'react';

export const JumbotronContext = React.createContext(null);

export const JumbotronContextProvider = (props) => {

  const [currentPreviewType, setCurrentPreviewType] = useState("");
  const [currentSlideURL, setCurrentSlideURL] = useState("");
  const [currentPresentation, setCurrentPresentation] = useState(null);
  const [currentLivestreamURL, setCurrentLivestreamURL] = useState("");
  const [currentPreviewSlideIndex, setCurrentPreviewSlideIndex] = useState(0);

  const [currentLiveType, setCurrentLiveType] = useState("");

  const [livestreamResolution, setLivestreamResolution] = useState("1280x720");
  const [livestreamFPS, setLivestreamFPS] = useState(15);

  const [disableCamAndMicSettings, toggleDisableCamAndMicSettings] = useState(false);

  const [disableScreenshareSettings, toggleDisableScreenshareSettings] = useState(false);

  const [disableMakePreviewLive, toggleDisableMakePreviewLive] = useState(false);

  const [isLive, toggleIsLive] = useState(false);

  const [blankMessage, setBlankMessage] = useState("");

  const [ hasSetScreenshareVariable, toggleHasSetScreenshareVariable ] = useState(false);

  useEffect(() => {
    console.log("Current preview type change", currentPreviewType);

    //If current preview type is a blank, disable make preview live. Otherwise, enable it
    if (currentPreviewType === "blank") {
      toggleDisableMakePreviewLive(true);
    } else {
      toggleDisableMakePreviewLive(false);
    }

  }, [currentPreviewType]);

  const jumbotronContextValue = {
    currentPreviewType,
    setCurrentPreviewType,
    currentSlideURL,
    setCurrentSlideURL,
    currentLivestreamURL,
    setCurrentLivestreamURL,
    currentPreviewSlideIndex,
    setCurrentPreviewSlideIndex,
    disableCamAndMicSettings,
    toggleDisableCamAndMicSettings,
    disableMakePreviewLive,
    toggleDisableMakePreviewLive,
    disableScreenshareSettings,
    toggleDisableScreenshareSettings,
    blankMessage,
    setBlankMessage,
    currentPresentation,
    setCurrentPresentation,
    isLive,
    toggleIsLive,
    livestreamResolution,
    setLivestreamResolution,
    livestreamFPS,
    setLivestreamFPS,
    currentLiveType,
    setCurrentLiveType,
    hasSetScreenshareVariable,
    toggleHasSetScreenshareVariable
  }

  return (
    <JumbotronContext.Provider value={jumbotronContextValue}>
      {props.children}
    </JumbotronContext.Provider>
  )
}
