import { Button, CircularProgress, MenuItem, Select, Typography } from '@material-ui/core';
import React, { ReactElement, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { JumbotronContext } from '../../../../Context/JumbotronContext';
import { PresenterToolsContext } from '../../../../Context/PresenterToolsContext';
import { ServerHelper } from '../../../../utilities/ServerHelper';

import styles from "./Slides.module.css";
import { SlidesStyles } from './SlidesStyles';

interface Props {

}

export default function Slides(props: Props): ReactElement {

  const {
    setCurrentPreviewType,
    setCurrentSlideURL,
    currentPreviewSlideIndex,
    setCurrentPreviewSlideIndex,
    setCurrentPresentation,
    currentPresentation,
    setBlankMessage,
  } = useContext(JumbotronContext);

  const { spacePresentations, setSpacePresentations } = useContext(PresenterToolsContext);

  const [selectedPresentation, setSelectedPresentation] = useState(null);

  const [loadingSpinner, toggleLoadingSpinner] = useState(true);

  //Set up cancel ability for axios request
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {

    //TODO: TODO: TODO:
    //Use selected presentation if selected already 
    //Also use previously-selected slide

    //Only get presentations if we haven't selected one already

    if (currentPresentation === null) {

      //Set blank message
      setBlankMessage("Please select a presentation");

      //Show blank on jumbotron
      ServerHelper.PreviewJumbotronBroadcastMediaStream.clear();

      setCurrentPreviewType("blank")

      const getSpacePresentations = async () => {

        try {
          //Get presentations for this booking's space
          let spacePresentations = await axios.get(ServerHelper.showboatBridgeAPIUrl + "/presentations/all", {
            params: {
              loginCode: ServerHelper.loginCode,
              bookingID: ServerHelper.loginResult.eventID
            },
            cancelToken: source.token
          });

          toggleLoadingSpinner(false);

          setSpacePresentations(spacePresentations.data);
        }
        catch (error) {
          //Do nothing if error is result of a cancel
          if (axios.isCancel(error)) {
            return;
          }

          if (error.response.status === 404) {
            SHOWBOAT.UIEventManager.OnUIError.Raise("No presentations found for this space.")
          } else {
            SHOWBOAT.UIEventManager.OnUIError.Raise("Error retrieving presentations for this space.")
          }

          //Hide loading spinner
          toggleLoadingSpinner(false);
        }
      }
      getSpacePresentations();
    } else {
      //Put last slide back on the jumbotron
      let newImg = document.createElement("img") as HTMLImageElement;
      newImg.id = "newImage";
      newImg.src = currentPresentation.slideRoot + currentPresentation.slides[currentPreviewSlideIndex];

      ServerHelper.PreviewJumbotronBroadcastMediaStream.addDrawableElement(
        "newImage",
        newImg,
        true,
        true,
        0,
        0,
        960,
        540
      );

      toggleLoadingSpinner(false);
      setCurrentPreviewType("slides");
      setSelectedPresentation(currentPresentation);

      setBlankMessage("Please select a slide");

    }

    return function cleanup() {
      //Cancel presentations-get axios request
      source.cancel("Component was unmounted");
    }

  }, [])

  //Slide selection
  const handleSlideSelect = (slide: number) => {

    console.log("Changing slide to slide", slide);

    //Create img element with slide's src
    let slideImgElement = document.createElement("img") as HTMLImageElement;
    slideImgElement.src = selectedPresentation.slideRoot + selectedPresentation.slides[slide];
    slideImgElement.id = "previewSlide";
    slideImgElement.crossOrigin = "anonymous";

    //Send image element to preview jumbotron
    ServerHelper.PreviewJumbotronBroadcastMediaStream.addDrawableElement(
      "previewSlide",
      slideImgElement,
      true,
      true,
      0,
      0,
      960,
      540,
    )

    //Set slide URL and index in context
    setCurrentSlideURL(SHOWBOAT.ApplicationSkin.slides.presentationSlides[slide]);
    setCurrentPreviewSlideIndex(slide)

    //Set preview type in context
    setCurrentPreviewType("slides");

  }

  //Presentation select
  const handlePresentationChange = (e) => {
    setSelectedPresentation(e.target.value);
    setCurrentPresentation(e.target.value);

    //If there is a first slide, put that on preview jumbotron
    let newPresentation = e.target.value;
    if (newPresentation.slides[0]) {
      let newPresentationImg = document.createElement("img") as HTMLImageElement;
      newPresentationImg.src = newPresentation.slideRoot + newPresentation.slides[0];
      newPresentationImg.id = "newPresentationImage";
      newPresentationImg.crossOrigin = "anonymous";

      //Put img on preview jumbotron
      ServerHelper.PreviewJumbotronBroadcastMediaStream.addDrawableElement(
        "newPresentationImage",
        newPresentationImg,
        true,
        true,
        0,
        0,
        960,
        540
      );

      setCurrentPreviewType("slides");

      setCurrentPreviewSlideIndex(0);

    }


  }

  const classes = SlidesStyles();

  return (
    <React.Fragment>

      <Typography variant="h2" className={classes.slideHeader}>
        SLIDES
      </Typography>

      {loadingSpinner &&
        <CircularProgress className={classes.loadingSpinner} />
      }

      <Select
        value={selectedPresentation !== null ? selectedPresentation : ""}
        className={classes.presentationSelect}
        onChange={handlePresentationChange}
        disabled={loadingSpinner}
      >
        {spacePresentations.map(presentation => {
          return (
            <MenuItem key={presentation.presentationID} value={presentation}>{presentation.name}</MenuItem>
          )
        })}
      </Select>

      <div className={styles.slideHolder}>

        {selectedPresentation !== null &&

          selectedPresentation.slides.map((slide, i) => {
            if (currentPreviewSlideIndex === i) {
              return (
                <div className={styles.imageHolder} key={i}>
                  <img
                    className={styles.slideThumbnail}
                    alt={`Slide ${i}`}
                    src={selectedPresentation.thumbnailRoot + slide}
                    key={i}
                    onClick={() => handleSlideSelect(i)}
                  />

                  <div className={styles.selectedSlideIndicator}>
                  </div>

                </div>

              )
            } else {
              return (
                <div className={styles.imageHolder} key={i}>
                  <img
                    className={styles.slideThumbnail}
                    alt={`Slide ${i}`}
                    src={selectedPresentation.thumbnailRoot + slide}
                    onClick={() => handleSlideSelect(i)}
                  />
                </div>
              )
            }
          })

        }

      </div>

    </React.Fragment>
  )
}
