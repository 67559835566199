import React, { ReactElement, useContext, useEffect, useState, useRef } from 'react';
import { ScreenshareStyles } from './ScreenshareStyles';
import styles from './Screenshare.module.css';
import fpsStore from '../../../../utilities/FPSStore';
import { ServerHelper } from '../../../../utilities/ServerHelper';
import { JumbotronContext } from '../../../../Context/JumbotronContext';
import { Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import resolutionStore from '../../../../utilities/ResolutionStore';

interface Props {

}

export default function Screenshare(props: Props): ReactElement {

  const {
    setCurrentPreviewType,
    disableScreenshareSettings,
    toggleDisableScreenshareSettings,
    setBlankMessage,
    currentPreviewType,
    isLive,
    livestreamResolution,
    livestreamFPS,
    setCurrentLiveType
  } = useContext(JumbotronContext);

  //Default FPS and Resolution to what is chosen for the livestream
  const [screenshareFPS, setScreenshareFPS] = useState(livestreamFPS);
  const [screenshareResolution, setScreenshareResolution] = useState(livestreamResolution);

  const currentPreviewTypeRef = useRef(currentPreviewType);
  const isLiveRef = useRef(false);

  useEffect(() => {

    if (isLive) {
      isLiveRef.current = true;
    }

    //If screenshare is running, show that on the preview
    if (ServerHelper.ScreenshareController) {

      //Sign up for stop events
      ServerHelper.ScreenshareController.OnScreenshareStopped.Add(handleStopScreenshare);

      if (ServerHelper.ScreenshareController.isScreenSharing()) {

        console.log("Is screensharing");

        let screenshareStream = ServerHelper.ScreenshareController.getScreenshareStream();

        //Show screenshare on preview
        ServerHelper.PreviewJumbotronBroadcastMediaStream.addMediaStream(
          screenshareStream,
          true,
          true,
          0,
          0,
          parseInt(screenshareResolution.split("x")[0]),
          parseInt(screenshareResolution.split("x")[1]),
        );

        setCurrentPreviewType("screenshare");

      } else {

        console.log("is not screensharing");

        //Set blank message in context
        setBlankMessage("Please start screenshare");

        //TODO: TODO: TODO: Show blank on jumbotron
        ServerHelper.PreviewJumbotronBroadcastMediaStream.clear();

        //Enable screenshare settings
        toggleDisableScreenshareSettings(false);

        setCurrentPreviewType("blank");
      }
    } else {

      //Set blank message in context
      setBlankMessage("Please start screenshare");

      //Show blank on jumbotron
      ServerHelper.PreviewJumbotronBroadcastMediaStream.clear();

      setCurrentPreviewType("blank");
    }

    return function cleanup() {
      console.log("CLEANUP CLEANUP");

      if (ServerHelper.ScreenshareController !== undefined) {
        ServerHelper.ScreenshareController.OnScreenshareStopped.Remove(handleStopScreenshare);
      }
    }


  }, []);

  useEffect(() => {
    currentPreviewTypeRef.current = currentPreviewType;
  }, [currentPreviewType]);

  useEffect(() => {
    isLiveRef.current = isLive;
  }, [isLive])

  const activateScreenshare = async () => {

    let width = parseInt(screenshareResolution.split("x")[0]);
    let height = parseInt(screenshareResolution.split("x")[1]);

    let fps = screenshareFPS;

    try {
      console.log("Starting screenshare with the following settings", width, height, fps)

      let screenshareStart =
        await ServerHelper.ScreenshareController.startScreenshare(
          true,
          width,
          height,
          screenshareFPS
        );

      if (screenshareStart) {
        let screenshareMediaStream = ServerHelper.ScreenshareController.getScreenshareStream();

        //Add listener for stop
        ServerHelper.ScreenshareController.OnScreenshareStopped.Add(handleStopScreenshare);

        //Activate screenshare in preview jumbotron
        ServerHelper.PreviewJumbotronBroadcastMediaStream.addMediaStream(
          screenshareMediaStream,
          true,
          true,
          0,
          0,
          1280,
          720
        );

        //Set preview type in context
        setCurrentPreviewType("screenshare");

      } else {
        //TODO: TODO: error handling
        //Permission denied, or error starting
        SHOWBOAT.UIEventManager.OnUIError.Raise("Error starting screenshare.")

        toggleDisableScreenshareSettings(false);
      }
    }
    catch (error) {
      return;
    }

  }

  useEffect(() => {

    return function cleanup() {
    }

  }, []);

  //Start screenshare
  const handleScreenshareStart = () => {
    //Disable settings
    toggleDisableScreenshareSettings(true);

    activateScreenshare();
  }

  //FPS change handler
  const handleScreenshareFPSChange = (e) => {

    //Set screenshare FPS

    setScreenshareFPS(e.target.value);
  }

  //Stop screenshare
  const handleScreenshareStopClick = () => {
    ServerHelper.ScreenshareController.stopScreenshare();

    handleStopScreenshare();
  }

  const handleStopScreenshare = () => {

    console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAA", currentPreviewType);

    //Enable screenshare settings
    toggleDisableScreenshareSettings(false);

    //Show blank in preview, only if we are currently previewing screenshare
    if (currentPreviewType === "screenshare") {
      console.log("Clearing preview jumbo");

      setBlankMessage("Please start screenshare");

      setCurrentPreviewType("blank");

      ServerHelper.PreviewJumbotronBroadcastMediaStream.clear();
    }

    //Show default image on main screen if we are currently live
    if (isLiveRef.current) {

      setCurrentLiveType("default");
      ServerHelper.ShowDefaultAssetOnLive();

    }
  }


  //Screenshare resolution change
  const handleScreenshareResolutionChange = (e) => {
    setScreenshareResolution(e.target.value);
  }

  const classes = ScreenshareStyles();

  return (
    <React.Fragment>

      <Typography variant="h2" className={classes.screenshareHeader}>
        SCREENSHARE
      </Typography>

      <div className={styles.screenshareHolder}>

        <Typography variant="h3" className={classes.fpsHeader}>
          FPS
        </Typography>
        <FormControl disabled={disableScreenshareSettings} classes={{ root: classes.fpsSelectHolder }}>
          <Select
            value={screenshareFPS}
            onChange={handleScreenshareFPSChange}
          >
            {fpsStore.map(fps => {
              return (
                <MenuItem key={fps} value={fps}>{fps}</MenuItem>
              )
            })}
          </Select>
        </FormControl>

        <Typography variant="h3" className={classes.resolutionHeader}>
          Resolution
        </Typography>

        <FormControl disabled={disableScreenshareSettings} classes={{ root: classes.resolutionSelectHolder }}>
          <Select
            value={screenshareResolution}
            onChange={handleScreenshareResolutionChange}
          >
            {resolutionStore.map(resolution => {
              return (
                <MenuItem key={resolution} value={resolution}>{resolution}</MenuItem>
              )
            })}
          </Select>
        </FormControl>

        <Button
          className={classes.startButton}
          variant="contained"
          onClick={handleScreenshareStart}
          disabled={disableScreenshareSettings}
        >
          START
      </Button>
        <Button
          className={classes.stopButton}
          variant="contained"
          onClick={handleScreenshareStopClick}
          disabled={!disableScreenshareSettings}
        >
          STOP
      </Button>
      </div>

    </React.Fragment>
  )
}
