import { makeStyles, createStyles } from '@material-ui/core/styles';

const ScreenshareStyles = makeStyles((theme: any) => createStyles({
  startButton: {
    backgroundColor: "#5EB904",
    position: "absolute",
    color: "white",
    bottom: 0,
    left: 0,
    padding: 0,
    width: 120,
    height: 44,
  },
  stopButton: {
    backgroundColor: "#5EB904",
    position: "absolute",
    color: "white",
    bottom: 0,
    left: 150,
    width: 120,
    height: 44,
  },
  fpsSelectHolder: {
    position: "absolute",
    left: 385,
    top: 80,
    width: 360,
    textAlign: "left",
    [theme.breakpoints.down('md')]: {
      width: 265,
      left: 290,
    }
  },
  resolutionSelectHolder: {
    position: "absolute",
    left: 0,
    top: 80,
    width: 365,
    textAlign: "left",
    [theme.breakpoints.down('md')]: {
      width: 265,
    }
  },
  screenshareHeader: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#FFCE00",
    position: "absolute",
    left: 0,
    top: 6
  },
  fpsHeader: {
    fontSize: 22,
    fontWeight: 400,
    position: "absolute",
    top: 45,
    left: 385,
    [theme.breakpoints.down('md')]: {
      left: 290,
    }
  },
  resolutionHeader: {
    fontSize: 22,
    fontWeight: 400,
    position: "absolute",
    top: 45,
    left: 0,
  }
})
)

export { ScreenshareStyles };