import { Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';

import styles from "./AudienceResponse.module.css";
import { AudienceResponseStyles } from './AudienceResponseStyles';

interface Props {

}

export default function AudienceResponse(props: Props): ReactElement {

  const classes = AudienceResponseStyles();

  return (
    <React.Fragment>
      <Typography className={classes.audienceResponseHeader}>
        AUDIENCE RESPONSE
      </Typography>

      <div className={styles.audienceResponseHolder}>

      </div>
    </React.Fragment>
  )
}
