import { makeStyles, createStyles } from '@material-ui/core/styles';

const MainStyles = makeStyles((theme: any) => createStyles({
  mainWrapper: {
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.intakePaper.main,
    textAlign: "center",
    overflowY: "auto"
  },
  bookingName: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 20,
    position: "absolute",
    left: 100,
    top: 30,
    fontWeight: 400,
  },
  homeButton: {
    position: "absolute",
    right: -10,
    top: 18,
  },
  errorButton: {
    position: "absolute",
    right: 50,
    top: 18,
    color: "#FFCE00"
  },
  errorTestButton: {
    position: "absolute"
  },
  errorTestButton2: {
    position: "absolute",
    top: 50,
  },
})
)

export { MainStyles };