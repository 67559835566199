import { makeStyles, createStyles } from '@material-ui/core/styles';

const JumbotronDisplayStyles = makeStyles((theme: any) => createStyles({
  jumbotronDisplayHolder: {
    width: "100%",
    height: 490,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    borderRadius: 10,
    [theme.breakpoints.down('md')]: {
      height: 425,
    }
  },
  makeLiveButton: {
    position: "absolute",
    padding: 0,
    left: "calc(50% - 120px)",
    width: 240,
    height: 50,
    bottom: 34,
    backgroundColor: "#5EB904",
    color: "white",
    [theme.breakpoints.down('md')]: {
      width: 180,
      left: "calc(50% - 90px)",
      bottom: 22
    }
  },
  banner: {
    width: 503,
    height: 44,
    display: "inline-block",
    backgroundColor: theme.palette.jumbotronBanner.main,
    position: "absolute",
    [theme.breakpoints.down('md')]: {
      width: 410,
    }
  },
  previewBanner: {
    left: 33,
    top: 32,
  },
  liveBanner: {
    left: 6,
    top: 32,
    width: 444,
    [theme.breakpoints.down('md')]: {
      width: 360,
    }
  },
  jumbotronHeader: {
    fontWeight: "bold",
    fontFamily: "Roboto, sans-serif",
    fontSize: 20,
    position: "absolute",
    left: 16,
    top: 10,
    color: "#959595",
    borderRadius: 4,
  },
  jumbotronHeaderLive: {
    left: 44,
  },
  liveIcon: {
    color: "#E20060",
    position: "absolute",
    left: 15,
    top: 10
  },
  settingsIconButton: {
    position: "absolute",
    right: 10,
    top: 3.5,
    padding: 6,
  },
  settingsIcon: {

  },
  playButton: {
    padding: 0,
    backgroundColor: "#5EB904",
    color: "white",
    borderRadius: 4,
    position: "absolute",
    width: 44,
    height: 44,
    right: -58,
    minWidth: 0,
  },
  playButtonLive: {
    backgroundColor: "#E20060"
  },
  playIcon: {
    color: "white",
  },
  stopIcon: {
    color: "white"
  },
  volumeHolder: {
    backgroundColor: theme.palette.jumbotronBanner.main,
    width: 358,
    height: 50,
    position: "absolute",
    right: 33,
    bottom: 34,
    [theme.breakpoints.down('md')]: {
      width: 300,
      right: 28,
      bottom: 22,
    }
  },
  volumeSlider: {
    height: 3,
    width: 266,
    top: 10,
    [theme.breakpoints.down('md')]: {
      width: 220,
    }
  },
  volumeSliderRail: {
    color: "white",
    height: 3,
  },
  volumeSliderTrack: {
    height: 3
  },
  volumeUpIcon: {
    position: "absolute",
    right: 7,
    top: 11
  },
  volumeMuteIcon: {
    position: "absolute",
    top: 11,
    left: 10
  }
})
)

export { JumbotronDisplayStyles };