import { CircularProgress, Paper } from '@material-ui/core';
import axios from 'axios';
import React, { ReactElement, useEffect, useState, useContext } from 'react';
import ReactDOM from 'react-dom';
import { PresenterToolsContext } from '../../Context/PresenterToolsContext';
import FailedLoginErrorPage from '../../Pages/FailedLoginErrorPage/FailedLoginErrorPage';
import { ServerHelper } from '../../utilities/ServerHelper';
import JumbotronDisplay from './JumbotronDisplay/JumbotronDisplay';
import PresenterTools from './PresenterTools/PresenterTools';

import styles from "./StreamCenter.module.css";
import { StreamCenterStyles } from './StreamCenterStyles';

interface Props {

}

export default function StreamCenter(props: Props): ReactElement {

  const { setSpacePresentations } = useContext(PresenterToolsContext);

  const [successfulLogin, toggleSuccessfulLogin] = useState(false);
  const [loadingSpinner, toggleLoadingSpinner] = useState(false);

  useEffect(() => {

    toggleLoadingSpinner(true);

    //Execute login and registration logic
    const onEnterStreamCenter = async () => {

      let socketLoginResult = await ServerHelper.OnEnterStreamCenter();

      console.log("Socket login result", socketLoginResult);

      if (socketLoginResult) {

        //Register to liveswitch
        let liveswitchRegister = ServerHelper.registerToLiveswitch();

        if (liveswitchRegister) {

          //Initialize devices
          SHOWBOAT.StreamingUserMedia.Init(true);

          toggleLoadingSpinner(false);

          toggleSuccessfulLogin(true);
        } else {

          //TODO: TODO: TODO:
          //Overlay screens with dead message

          toggleLoadingSpinner(false);

          toggleSuccessfulLogin(true);

          //Show error notification
          SHOWBOAT.UIEventManager.OnUIError.Raise("Error connecting to liveswitch. Attempting to reconnect.")
        }

      } else {
        //Show error page
        SHOWBOAT.UIEventManager.OnUIError.Raise("Error connecting to server. Attempting to reconnect.");
      }


    };
    onEnterStreamCenter();

  }, []);

  const classes = StreamCenterStyles();

  if (successfulLogin) {
    return (
      <React.Fragment>



        <JumbotronDisplay />

        <PresenterTools />
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
        {loadingSpinner &&
          <CircularProgress className={classes.loadingSpinner} />
        }
      </React.Fragment>
    )
  }

}
