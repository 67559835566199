import { makeStyles, createStyles } from '@material-ui/core/styles';

const ErrorPopupStyles = makeStyles((theme: any) => createStyles({
  errorPopup: {
    width: "100%",
    height: "100%",
    position: 'absolute',
  },
  popup: {
    width: 452,
    minHeight: 0,
    height: "auto",
    margin: 0,
    overflowX: "hidden",
    overflowY: "visible",
    scrollbarWidth: "thin",
    textAlign: "center",
    paddingBottom: 90,
    borderRadius: 10,
  },
  errorsHeader: {
    color: "#FFCE00",
    fontSize: 14,
    fontWeight: 400,
    position: "absolute",
    left: 30,
    top: 25
  },
  closeButton: {
    padding: 4,
    position: "absolute",
    top: 15,
    right: 10
  }
})
)

export { ErrorPopupStyles };