import React, { useState, useEffect, useRef } from 'react';
import { ServerHelper } from '../utilities/ServerHelper';

export const ErrorContext = React.createContext(null);

export const ErrorContextProvider = (props) => {

  const [errorMessages, setErrorMessages] = useState([]);

  const errorMessagesRef = useRef([]);

  useEffect(() => {

    //Add listener for UI Errors
    SHOWBOAT.UIEventManager.OnUIError.Add(handleUIError);

    //Listen for SocketIO disconnection
    SHOWBOAT.SocketIOController.OnLocalPlayerDisconnected.Add(handleLocalPlayerDisconnected);

    //Listen for socket reconnection to hide socket error
    SHOWBOAT.SocketIOController.OnLocalPlayerReconnected.Add(handleLocalPlayerReconnected);

    return function cleanup() {
      SHOWBOAT.UIEventManager.OnUIError.Remove(handleUIError);
    }
  }, []);

  useEffect(() => {
    errorMessagesRef.current = errorMessages;
  }, [errorMessages])

  const handleUIError = (error: string) => {
    console.log("NEW UI ERROR", error, errorMessagesRef.current);

    //Don't show duplicate errors
    if (!errorMessagesRef.current.includes(error)) {
      errorMessagesRef.current = [...errorMessagesRef.current, error];
      setErrorMessages(errorMessagesRef.current);
    }
  }

  const handleLocalPlayerReconnected = () => {
    errorMessagesRef.current = errorMessagesRef.current.filter(function(err) {
      return (err !== "Error connecting to server. Attempting to reconnect.")
    });

    setErrorMessages(errorMessagesRef.current);
  }

  const handleLocalPlayerDisconnected = () => {
    SHOWBOAT.UIEventManager.OnUIError.Raise("Error connecting to server. Attempting to reconnect.");
  }

  const errorContextValue = {
    errorMessages,
    setErrorMessages
  }

  return (
    <ErrorContext.Provider value={errorContextValue}>
      {props.children}
    </ErrorContext.Provider>
  )
}