import { makeStyles, createStyles } from '@material-ui/core/styles';

const PreviewJumbotronStyles = makeStyles((theme: any) => createStyles({
  pauseOnLive: {
    position: "relative",
    top: 32,
    right: 45
  },
  blankText: {
    position: "absolute",
    color: "white",
    fontFamily: "Roboto, sans-serif",
    fontSize: 16,
    top: 120,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
  },
  blankMessage: {
    color: "white",
    position: "absolute",
    fontSize: 16,
    top: 120,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    [theme.breakpoints.down('md')]: {
      top: 100,
    }
  },
  
})
)

export { PreviewJumbotronStyles };