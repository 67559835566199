import { makeStyles, createStyles } from '@material-ui/core/styles';

const SettingsPopupStyles = makeStyles((theme: any) => createStyles({
  settingsPopup: {
    width: "100%",
    height: "100%",
    position: 'absolute',
  },
  popup: {
    width: 452,
    minHeight: 380,
    height: "auto",
    borderRadius: 0,
    margin: 0,
    overflowX: "hidden",
    overflowY: "visible",
    scrollbarWidth: "thin",
    textAlign: "center",
    paddingBottom: 30
  },
  settingsHeader: {
    color: "#FFCE00",
    fontSize: 14,
    position: "absolute",
    left: 0,
    top: 0,
    fontWeight: "bold"
  },
  closeButton: {
    position: "absolute",
    top: -12,
    right: -12,
    padding: 8,
  },
  selectHeader: {
    fontSize: 22,
    fontFamily: "Roboto, sans-serif",
    position: "relative",
  },
  resolutionHeader: {
    top: 45,
  },
  select: {
    width: 386,
    position: "relative",
  },
  resolutionSelect: {
    top: 60,
  },
  fpsHeader: {
    top: 110,
  },
  fpsSelect: {
    top: 125,
  },
  cancelButton: {
    padding: 0,
    width: 107,
    height: 50,
    right: 130,
    bottom: 35,
    position: "absolute",
  },
  saveButton: {
    backgroundColor: "#5EB904",
    padding: 0,
    width: 107,
    height: 50,
    position: "absolute",
    right: 0,
    bottom: 35,
    color: "white"
  },
  warningIcon: {
    color: "#FFB700",
    fontSize: 45,
    position: "relative",
    top: 15,
  },
  warningText: {
    fontSize: 16,
    color: "#00B6FF",
    position: "relative",
    left: 30,
    top: 14,
    display: "inline-block",
    width: 300,
  }
})
)

export { SettingsPopupStyles };