import React, { ReactElement, useContext, useEffect, useState, useRef } from 'react';
import { JumbotronContext } from '../../../../Context/JumbotronContext';
import { ServerHelper } from '../../../../utilities/ServerHelper';

import styles from './LiveJumbotron.module.css';

interface Props {

}

export default function LiveJumbotron(props: Props): ReactElement {

  useEffect(() => {
    //On mount, instantiate live version of Broadcast class
    let classInstance =
      new SHOWBOAT.BroadcastMediaStream(1280, 720, 15, SHOWBOAT.AudioContextManager.AudioContext, true);

    //Start the class instance after construction
    classInstance.start();

    ServerHelper.LiveJumbotronBroadcastMediaStream = classInstance;

    //Append canvas to live div
    let liveCanvas = ServerHelper.LiveJumbotronBroadcastMediaStream.getCanvas();
    let liveContainer = document.getElementById("liveJumbotron");

    if (liveContainer !== null) {
      liveContainer.appendChild(liveCanvas);
    }

    return function cleanup() {
    }

  }, []);


  return (
    <div id="liveJumbotron" className={styles.liveJumbotron}>

    </div>
  )
}
