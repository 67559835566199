import { Button, Dialog, FormControl, IconButton, MenuItem, Select, Typography } from '@material-ui/core';
import React, { ReactElement, useState, useContext } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';

import styles from "./SettingsPopup.module.css";
import { SettingsPopupStyles } from './SettingsPopupStyles';
import resolutionStore from '../../../../utilities/ResolutionStore';
import fpsStore from '../../../../utilities/FPSStore';
import { JumbotronContext } from '../../../../Context/JumbotronContext';
import { ServerHelper } from '../../../../utilities/ServerHelper';

interface Props {
  handlePopupClose: () => any,
}

export default function SettingsPopup(props: Props): ReactElement {

  const {
    isLive,
    livestreamResolution,
    setLivestreamResolution,
    livestreamFPS,
    setLivestreamFPS,
    toggleDisableCamAndMicSettings,
    currentPreviewType
  } = useContext(JumbotronContext);

  //Resolution select
  const handleResolutionChange = (e) => {
    //Set value in context
    setLivestreamResolution(e.target.value);
  }

  //FPS select
  const handleFPSChange = (e) => {
    //Set value in context
    setLivestreamFPS(e.target.value);
  }

  //Close
  const handleCloseButtonClick = () => {
    props.handlePopupClose();
  }

  //Save
  const handleSaveClick = () => {

    //TODO: TODO:
    //Only change fps on broadcasts if they are changed

    //Stop broadcast instances, then re-start with new settings
    ServerHelper.PreviewJumbotronBroadcastMediaStream.stop();
    ServerHelper.LiveJumbotronBroadcastMediaStream.stop();

    //Change settings of preview and live broadcast
    ServerHelper.LiveJumbotronBroadcastMediaStream.setOutputSize(
      parseInt(livestreamResolution.split("x")[0]),
      parseInt(livestreamResolution.split("x")[1])
    );

    ServerHelper.PreviewJumbotronBroadcastMediaStream.setOutputSize(
      parseInt(livestreamResolution.split("x")[0]),
      parseInt(livestreamResolution.split("x")[1])
    );

    //Stop screenshare
    ServerHelper.ScreenshareController.stopScreenshare();

    //If we have default on preview, put that back on preview
    if (currentPreviewType === "default") {
      setDefaultOnPreview();
    }

    toggleDisableCamAndMicSettings(false);

    ServerHelper.PreviewJumbotronBroadcastMediaStream.setFPS(livestreamFPS);
    ServerHelper.LiveJumbotronBroadcastMediaStream.setFPS(livestreamFPS);

    ServerHelper.PreviewJumbotronBroadcastMediaStream.start();
    ServerHelper.LiveJumbotronBroadcastMediaStream.start();

    //Close popup
    props.handlePopupClose();
  }

  const setDefaultOnPreview = () => {
    if (SHOWBOAT.ApplicationSkin.worldSkin.jumboTronDefaultImage.type === "Video") {
      //Show default video on jumbotron
      let defaultVideo = document.createElement("video") as HTMLVideoElement;

      defaultVideo.id = "defaultVideo";
      defaultVideo.src = SHOWBOAT.ApplicationSkin.worldSkin.jumboTronDefaultImage.value;
      defaultVideo.crossOrigin = "anonymous";

      //Play and loop the video
      defaultVideo.play();
      defaultVideo.loop = true;

      ServerHelper.PreviewJumbotronBroadcastMediaStream.addVideoElement(
        "defaultVideo",
        defaultVideo,
        true,
        true,
        0,
        0
      )

    } else if (SHOWBOAT.ApplicationSkin.worldSkin.jumboTronDefaultImage.type === "Image") {
      //Show default image on jumbotron
      let defaultImg = document.createElement("img") as HTMLImageElement;
      defaultImg.id = "defaultImg";
      defaultImg.src = SHOWBOAT.ApplicationSkin.worldSkin.jumboTronDefaultImage.value;
      defaultImg.crossOrigin = "anonymous";

      ServerHelper.PreviewJumbotronBroadcastMediaStream.addDrawableElement(
        "defaultImg",
        defaultImg,
        true,
        true,
        0,
        0,
        960,
        540
      )
    }
    else {
      //TODO: TODO: TODO: TODO:
      //Handle other asset types for default
      //do nothing for now
    }
  }

  //Cancel
  const handleCancelClick = () => {
    props.handlePopupClose();
  }

  const classes = SettingsPopupStyles();

  return (
    <Dialog
      open={true}
      className={classes.settingsPopup}
    >
      <div
        className={classes.popup}
      >
        <div className={styles.streamingSettingsHolder}>
          <Typography variant="h2" className={classes.settingsHeader}>
            STREAMING SETTINGS
          </Typography>

          {isLive &&
            <div className={styles.liveWarningHolder}>
              <WarningIcon className={classes.warningIcon} />

              <Typography variant="body1" className={classes.warningText}>
                Streaming settings cannot be updated while streaming.
              </Typography>

            </div>
          }

          <IconButton
            className={classes.closeButton}
            onClick={handleCloseButtonClick}
          >
            <CloseIcon />
          </IconButton>

          <Typography variant="h3" className={`${classes.selectHeader} ${classes.resolutionHeader}`}>
            Resolution
          </Typography>

          <FormControl
            classes={{ root: `${classes.select} ${classes.resolutionSelect}` }}
            disabled={isLive}
          >
            <Select
              value={livestreamResolution}
              onChange={handleResolutionChange}
            >
              {resolutionStore.map(resolution => {
                return (
                  <MenuItem key={resolution} value={resolution}>{resolution}</MenuItem>
                )
              })}
            </Select>
          </FormControl>

          <Typography variant="h3" className={`${classes.selectHeader} ${classes.fpsHeader}`}>
            FPS
          </Typography>

          <FormControl
            classes={{ root: `${classes.select} ${classes.fpsSelect}` }}
            disabled={isLive}
          >
            <Select
              value={livestreamFPS}
              onChange={handleFPSChange}
            >
              {fpsStore.map(fps => {
                return (
                  <MenuItem key={fps} value={fps} onChange={handleFPSChange}>{fps}</MenuItem>
                )
              })}
            </Select>
          </FormControl>

          <Button
            className={classes.saveButton}
            onClick={handleSaveClick}
            variant="contained"
            disabled={isLive}
          >
            SAVE
          </Button>

          <Button
            variant="text"
            className={classes.cancelButton}
            onClick={handleCancelClick}
          >
            CANCEL
          </Button>

        </div>

      </div>
    </Dialog>
  )
}
