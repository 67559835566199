import { Checkbox, FormControlLabel, Paper, Slider, Typography } from '@material-ui/core';
import React, { ReactElement, useEffect, useState, useContext } from 'react';
import { JumbotronContext } from '../../../../Context/JumbotronContext';
import { ServerHelper } from '../../../../utilities/ServerHelper';
import PlayControls from '../PlayControls/PlayControls';

import styles from './PreviewJumbotron.module.css';
import { PreviewJumbotronStyles } from './PreviewJumbotronStyles';

interface Props {

}

export default function PreviewJumbotron(props: Props): ReactElement {

  const { currentPreviewType, blankMessage } = useContext(JumbotronContext);

  const [pauseOnLive, togglePauseOnLive] = useState(false);
  const [hls, setHLS] = useState(null);

  const [showBlankMessage, toggleShowBlankMessage] = useState(false);

  useEffect(() => {


    //Create Preview instance of BroadcastMediaStream
    let classInstance = new SHOWBOAT.BroadcastMediaStream(1280, 720, 15, SHOWBOAT.AudioContextManager.AudioContext, true);
    //Start the broadcast instance after construction
    classInstance.start();

    ServerHelper.PreviewJumbotronBroadcastMediaStream = classInstance;

    //Listen for layer changes
    ServerHelper.PreviewJumbotronBroadcastMediaStream.OnLayerCountChanged.Add(handleLayerCountChange);

    //Add canvas to preview div
    let previewCanvas = ServerHelper.PreviewJumbotronBroadcastMediaStream.getCanvas();

    previewCanvas.id = "previewJumbotronCanvas";

    let previewDiv = document.getElementById("previewJumbotron");

    if (previewDiv !== null) {
      previewDiv.appendChild(previewCanvas);
    }

    return function cleanup() {
    }

  }, []);

  useEffect(() => {


  }, []);

  //Layers cleared handler to show blank message
  const handleLayerCountChange = (count: number) => {
    console.log("Layer count change", count);

    //Show blank message if layers are set to 0
    if (count === 0) {
      toggleShowBlankMessage(true);
    } else {
      toggleShowBlankMessage(false);
    }
  }


  //Pause on live
  const handlePauseLiveChange = (e) => {
    console.log("EVENT", e.target.checked);
    //If pausing, pause live video
    let liveVideo = document.getElementById("localVideo") as HTMLVideoElement;

    if (liveVideo !== null) {
      if (e.target.checked) {
        liveVideo.pause();
      } else {
        liveVideo.play();
      }
    }


    togglePauseOnLive(e.target.checked);
  }

  const classes = PreviewJumbotronStyles();

  console.log("BLANK MESSAGE", blankMessage);

  return (
    <div id="previewJumbotron" className={styles.previewJumbotron}>

      {/* Show blank message when there are no layers on the canvas */}
      {showBlankMessage &&
        < Typography variant="body1" className={classes.blankMessage}>
          {blankMessage}
        </Typography>
      }

    </div >
  )
}
