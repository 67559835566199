import { Button, Menu, MenuItem, Paper, Typography } from '@material-ui/core';
import React, { ReactElement, useState } from 'react';

import { PresenterToolsSelectionStyles } from './PresenterToolsSelectionStyles';
import styles from './PresenterToolsSelection.module.css';

interface Props {
  setCurrentPresenterTool: (presenterTool: string) => void,
  currentPresenterTool: string
}

export default function PresenterToolsSelection(props: Props): ReactElement {

  const classes = PresenterToolsSelectionStyles();

  return (
    <Paper className={classes.presenterToolsSelectionWrapper}>

      <Typography variant="h3" className={classes.sourcesHeader}>
        SOURCES
      </Typography>

      <div className={styles.presenterToolsSelectionHolder}>
        <Button
          classes={{
            root: classes.presenterToolButton,
            label: props.currentPresenterTool === "sbconnect"
              ? `${classes.presenterToolButtonLabel} ${classes.presenterToolButtonLabelSelected}`
              : classes.presenterToolButtonLabel
          }}
          variant="text"
          onClick={() => props.setCurrentPresenterTool("sbconnect")}
        >
          VIRTUAL CAMERA
        </Button>
        <Button
          classes={{
            root: classes.presenterToolButton,
            label: props.currentPresenterTool === "hls"
              ? `${classes.presenterToolButtonLabel} ${classes.presenterToolButtonLabelSelected}`
              : classes.presenterToolButtonLabel
          }}
          variant="text"
          onClick={() => props.setCurrentPresenterTool("hls")}
        >
          HLS LIVESTREAM
        </Button>
        <Button
          classes={{
            root: classes.presenterToolButton,
            label: props.currentPresenterTool === "slides"
              ? `${classes.presenterToolButtonLabel} ${classes.presenterToolButtonLabelSelected}`
              : classes.presenterToolButtonLabel
          }}
          variant="text"
          onClick={() => props.setCurrentPresenterTool("slides")}
        >
          SLIDES
        </Button>
        <Button
          classes={{
            root: classes.presenterToolButton,
            label: props.currentPresenterTool === "screenshare"
              ? `${classes.presenterToolButtonLabel} ${classes.presenterToolButtonLabelSelected}`
              : classes.presenterToolButtonLabel
          }}
          variant="text"
          onClick={() => props.setCurrentPresenterTool("screenshare")}
        >
          SCREENSHARE
        </Button>
        <Button
          classes={{
            root: classes.presenterToolButton,
            label: props.currentPresenterTool === "localvideo"
              ? `${classes.presenterToolButtonLabel} ${classes.presenterToolButtonLabelSelected}`
              : classes.presenterToolButtonLabel
          }}
          variant="text"
          onClick={() => props.setCurrentPresenterTool("localvideo")}
        >
          LOCAL VIDEO
        </Button>
        {/* <Button
          classes={{
            root: classes.presenterToolButton,
            label: props.currentPresenterTool === "presentersWebcam"
              ? `${classes.presenterToolButtonLabel} ${classes.presenterToolButtonLabelSelected}`
              : classes.presenterToolButtonLabel
          }}
          variant="text"
          onClick={() => props.setCurrentPresenterTool("presentersWebcam")}
        >
          PRESENTERS WEBCAM
        </Button> */}
        {/*  <Button
          classes={{
            root: classes.presenterToolButton,
            label: props.currentPresenterTool === "audienceResponse"
              ? `${classes.presenterToolButtonLabel} ${classes.presenterToolButtonLabelSelected}`
              : classes.presenterToolButtonLabel
          }}
          variant="text"
          onClick={() => props.setCurrentPresenterTool("audienceResponse")}
        >
          AUDIENCE RESPONSE
        </Button> */}
        <Button
          classes={{
            root: classes.presenterToolButton,
            label: props.currentPresenterTool === "default"
              ? `${classes.presenterToolButtonLabel} ${classes.presenterToolButtonLabelSelected}`
              : classes.presenterToolButtonLabel
          }}
          variant="text"
          onClick={() => props.setCurrentPresenterTool("default")}
        >
          DEFAULT SCREEN
        </Button>
      </div>

    </Paper>
  )
}
