import { Paper } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { CurrentPresenterToolStyles } from './CurrentPresenterToolStyles';

import styles from './CurrentPresenterTool.module.css';
import ShowboatConnect from '../ShowboatConnect/ShowboatConnect';
import HLSLivestream from '../HLSLivestream/HLSLivestream';
import Slides from '../Slides/Slides';
import LocalVideo from '../LocalVideo/LocalVideo';
import Screenshare from '../Screenshare/Screenshare';
import DefaultScreen from '../DefaultScreen/DefaultScreen';
import PresentersWebcam from '../PresentersWebcam/PresentersWebcam';
import AudienceResponse from '../AudienceResponse/AudienceResponse';

interface Props {
  currentPresenterTool: string,
}

export default function CurrentPresenterTool(props: Props): ReactElement {

  const classes = CurrentPresenterToolStyles();

  return (
    <Paper className={classes.currentPresenterToolHolder}>
      <div className={styles.presenterToolHolder}>
        {
          {
            "sbconnect": <ShowboatConnect />,
            "hls": <HLSLivestream />,
            "slides": <Slides />,
            "localvideo": <LocalVideo />,
            "screenshare": <Screenshare />,
            "presentersWebcam": <PresentersWebcam />,
            "audienceResponse": <AudienceResponse />,
            "default": <DefaultScreen />
          }[props.currentPresenterTool]
        }
      </div>
    </Paper>
  )
}
