import { makeStyles, createStyles } from '@material-ui/core/styles';

const ErrorMessageStyles = makeStyles((theme: any) => createStyles({
  errorMessageHolder: {
    width: 400,
    height: 105,
    backgroundColor: theme.palette.errorMessage.main,
    boxShadow: "none",
    display: "inline-block",
    pointerEvents: "all",
    marginBottom: 10,
    left: 0,
  },
  errorMessage: {
    fontSize: 14,
    color: "#3eabe3",
    display: "inline-block",
    width: 200,
    position: "relative",
    right: 35,
  },
  errorIcon: {
    color: "#FFCE00",
    position: "absolute",
    left: 20,
    top: 7,
    fontSize: 30
  },
  refreshButton: {
    position: "absolute",
    bottom: 10,
    right: 150,
  },
  closeButton: {
    bottom: 10,
    right: 50,
    position: "absolute",
  }
})
)

export { ErrorMessageStyles };