import { makeStyles, createStyles } from '@material-ui/core/styles';

const AudienceResponseStyles = makeStyles((theme: any) => createStyles({
  audienceResponseHeader: {
    color: "#FFCE00",
    fontSize: 14,
    position: "absolute",
    top: 6,
    left: 0,
    fontWeight: "bold"
  }
})
)

export { AudienceResponseStyles };