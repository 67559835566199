import React, { ReactElement, useState } from 'react';
import CurrentPresenterTool from './CurrentPresenterTool/CurrentPresenterTool';

import styles from './PresenterTools.module.css';
import PresenterToolsSelection from './PresenterToolsSelection/PresenterToolsSelection';

interface Props {

}

export default function PresenterTools(props: Props): ReactElement {

  const [currentPresenterTool, setCurrentPresenterTool] = useState("default");

  return (
    <div className={styles.presenterToolsHolder}>
      <PresenterToolsSelection
        currentPresenterTool={currentPresenterTool}
        setCurrentPresenterTool={setCurrentPresenterTool}
      />

      <CurrentPresenterTool
        currentPresenterTool={currentPresenterTool}
      />

    </div>
  )
}
