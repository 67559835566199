import { Button, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import React, { ReactElement, useEffect, useState, useRef, useContext } from 'react';
import { JumbotronContext } from '../../../../Context/JumbotronContext';
import { ServerHelper } from '../../../../utilities/ServerHelper';

import styles from "./LocalVideo.module.css";
import { LocalVideoStyles } from './LocalVideoStyles';

interface Props {

}

export default function LocalVideo(props: Props): ReactElement {

  const { setCurrentPreviewType, setBlankMessage } = useContext(JumbotronContext);

  const [playOrPause, setPlayOrPause] = useState("");

  const [inputFiles, setInputFiles] = useState([]);

  const fileInputRef = useRef(null);

  function handleVideoUpload(event) {

    //Clear out preview jumbotron video
    clearPreviewJumbotronVideo();

    let acceptedFileTypes = ["video/mp4", "video/quicktime", "video/m4v"];

    if (!acceptedFileTypes.includes(this.files[0].type) || this.files[0] === undefined) {
      return;
    };

    if (this.files.length === 0) {
      return;
    } else {

      let file = this.files[0];

      setInputFiles(this.files[0]);

      console.log("FILE FILE", this.files[0])

      let videoNode = document.createElement("video");

      videoNode.id = "localVideoPreview";

      let fileURL = URL.createObjectURL(file);

      videoNode.src = fileURL;

      //Set video node to loop 
      videoNode.loop = true;

      videoNode.play();

      videoNode.controls = true;

      //Append video node to preview jumbotron
      let videoHolder = document.getElementById("previewJumbotron");
      if (videoHolder !== null) {
        videoHolder.appendChild(videoNode);
      }

      //Set up volume level change listener/muted listener
      videoNode.onvolumechange = function () {

        if (videoNode.muted) {
          SHOWBOAT.UIEventManager.OnLocalVideoShareVolumeChange.Raise(0);
          return;
        }

        SHOWBOAT.UIEventManager.OnLocalVideoShareVolumeChange.Raise(videoNode.volume);

      }

      //Show this video on the preview canvas
      ServerHelper.PreviewJumbotronBroadcastMediaStream.addVideoElement(
        "localVideoPreview",
        videoNode,
        true,
        true,
        0,
        0,
        videoNode.videoWidth,
        videoNode.videoHeight,
      );

      //Set preview type in context
      setCurrentPreviewType("localvideo");

    }
  }

  const clearPreviewJumbotronVideo = () => {
    let previewVideo = document.getElementById("localVideoPreview");

    if (previewVideo !== null) previewVideo.parentNode.removeChild(previewVideo);
  }

  useEffect(() => {
    //Set up listener for file input 
    let fileInput = document.getElementById("fileInput");

    fileInput.addEventListener("change", handleVideoUpload, false);

    //Set blank message in context
    setBlankMessage("Please upload a video to start")

    //Show blank in preview jumbotron
    ServerHelper.PreviewJumbotronBroadcastMediaStream.clear();

    setCurrentPreviewType("blank");

    return function cleanup() {
      let fileInput = document.getElementById("fileInput");

      if (fileInput !== null) {
        fileInput.removeEventListener("change", handleVideoUpload, false);
      }

      //Remove localVideo from preview jumbotron
      let previewJumbotronVideo = document.querySelector("#localVideoPreview");
      if (previewJumbotronVideo !== null) {
        previewJumbotronVideo.parentNode.removeChild(previewJumbotronVideo);
      }
    }
  }, [])

  //Play vs. pause change
  const handlePlayOrPauseChange = (e) => {
    setPlayOrPause(e.target.value);
  }

  //Choose file click
  const handleChooseFileClick = () => {
    fileInputRef.current.click();
  }

  const classes = LocalVideoStyles();

  console.log("INPUT FILES", inputFiles);

  return (
    <React.Fragment>

      <Typography variant="h3" className={classes.localVideoHeader}>
        LOCAL VIDEO
      </Typography>

      <input
        type="file"
        id="fileInput"
        accept="video/mp4,video/quicktime,video/m4v"
        style={{ display: "none" }}
        ref={fileInputRef}
      />

      <div className={styles.fileNameHolder}>
        <Typography variant="h4" className={classes.fileNameHeader}>
          FILE NAME
        </Typography>
      </div>

      <div className={styles.fileNameValueHolder}>
        {(inputFiles as any).name !== undefined &&
          <Typography variant="body1" className={classes.fileName}>
            {(inputFiles as any).name}
          </Typography>
        }
      </div>


      <Button
        className={classes.chooseFileButton}
        onClick={handleChooseFileClick}
      >
        CHOOSE FILE
      </Button>

    </React.Fragment>
  )
}
