import React, { ReactElement, useEffect, useRef, useState, useContext } from 'react';
import $ from 'jquery';

import styles from './ErrorStack.module.css';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { ErrorContext } from '../../../../Context/ErrorContext';

interface Props {

}

export default function ErrorStack(props: Props): ReactElement {

  const {
    errorMessages,
    setErrorMessages
  } = useContext(ErrorContext);

  const [errors, setErrors] = useState([]);

  const errorsRef = useRef([]);

  let errorID = 0;

  useEffect(() => {
    //Listen for UI errors
    SHOWBOAT.UIEventManager.OnUIError.Add(handleUIError);

    //Listen for socket reconnection
    SHOWBOAT.SocketIOController.OnLocalPlayerReconnected.Add(handleLocalPlayerReconnected);
  }, []);

  $(document).ready(function () {
    $('.closeButtonStack').on("click", function (e) {
      var $thisParent = $(this).closest('div');
      $thisParent.nextAll().each(function (index) {
        $(this).stop().animate({
          top: `-=${$thisParent.outerHeight(true)}`
        }, 300)
      });
    });
  })

  const handleUIError = (error: string) => {

    //Only execute logic if error is not already in stack
    let errMessageArray = [];
    errorsRef.current.forEach(err => {
      errMessageArray.push(err.error);
    });

    console.log(errMessageArray)

    if (!errMessageArray.includes(error)) {
      console.log("A");

      //Construct error object
      let newError = {
        error: error,
        errorID: errorID
      };

      //Animate stack
      $(".errorInStack").animate({
        top: "+=115px",
      }, 300);

      //Increment errorID
      errorID++;

      errorsRef.current = [newError, ...errorsRef.current];

      setErrors(errorsRef.current);
    } else {
      console.log("B");
    }

  }

  //Error close
  const handleErrorClose = (errorMessage: string) => {
    //Remove error message from state and context
    errorsRef.current = errorsRef.current.filter(function (err) {
      return (err.error !== errorMessage);
    })

    setErrors(errorsRef.current);

    setErrorMessages(errorMessages.filter(function (err) {
      return (err !== errorMessage);
    }));
  };

  const handleLocalPlayerReconnected = () => {
    //Remove socket error from stack 
    errorsRef.current = errorsRef.current.filter(function(err) {
      return (err.error !== "Error connecting to server. Attempting to reconnect.");
    });

    setErrors(errorsRef.current);
  }

  return (
    <div className={styles.errorStack}>
      {errors.map((error, i) => {
        return (
          <ErrorMessage
            errorMessage={error.error}
            key={error.errorID}
            handleErrorClose={handleErrorClose}
            errorStack={true}
          />
        )

      })}
    </div>
  )
}
