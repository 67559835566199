import { makeStyles, createStyles } from '@material-ui/core/styles';

const LocalVideoStyles = makeStyles((theme: any) => createStyles({
  chooseFileButton: {
    padding: 0,
    width: 163,
    height: 44,
    display: "inline-block",
    color: "white",
    backgroundColor: "#5EB904",
    position: "absolute",
    left: 0,
    top: 170,
  },
  radioOption: {
    color: "white",
    fontFamily: "Roboto, sans-serif",
    fontSize: 14
  },
  radioChecked: {
    color: `${theme.palette.primary.main} !important`
  },
  localVideoHeader: {
    fontSize: 14,
    left: 0,
    top: 6,
    color: "#FFCE00",
    fontWeight: "bold",
    position: "absolute",
  },
  fileNameHeader: {
    fontSize: 16,
    fontWeight: 400,
    position: "absolute",
    top: 17,
    left: 20,
  },
  fileName: {
    fontSize: 16,
    fontWeight: 400,
    position: "absolute",
    top: 15,
    left: 30,
  }
})
)

export { LocalVideoStyles };
