import { IconButton, Typography } from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';

import styles from "./PresentersWebcam.module.css";
import { PresentersWebcamStyles } from './PresentersWebcamStyles';
import PresenterItem from './PresenterItem/PresenterItem';

interface Props {

}

export default function PresentersWebcam(props: Props): ReactElement {

  const [presenters, setPresenters] = useState([]);
  const [selectedPresenterID, setSelectedPresenterID] = useState("");

  useEffect(() => {

    //On load, get all presenters in a room
    let presenterArr = SHOWBOAT.RemoteAvatarDataManager.getAllAvatarsInEventByRole("presenter");

    setPresenters(presenterArr);

  }, [])

  //Refresh
  const handleRefreshClick = () => {
    setPresenters(SHOWBOAT.RemoteAvatarDataManager.getAllAvatarsInEventByRole("presenter"))
  };

  useEffect(() => {
    console.log("PRESENTERS", presenters);
  }, [presenters])

  const classes = PresentersWebcamStyles();

  return (
    <React.Fragment>
      <Typography variant="h2" className={`${classes.header} ${classes.presentersWebcamHeader}`}>
        PRESENTERS
      </Typography>

      <IconButton
        className={classes.refreshButton}
        onClick={handleRefreshClick}
      >
        <RefreshIcon />
      </IconButton>

      <Typography variant="h2" className={`${classes.header} ${classes.webcamHeader} `}>
        WEBCAM
      </Typography>

      <div
        className={presenters.length > 1
          ? `${styles.presentersHolder} ${styles.presentersHolderTopBorder}`
          : styles.presentersHolder
        }
      >

        {/* Skip over ourselves */}
        {presenters.map(presenter => {
          if (presenter.userID !== SHOWBOAT.LocalAvatarDataManager.userID) {
            return (
              <PresenterItem
                firstName={presenter.firstName}
                lastName={presenter.lastName}
                userID={presenter.userID}
                cameraEnabled={presenter.cameraEnabled}
                selectedPresenterID={selectedPresenterID}
                setSelectedPresenterID={setSelectedPresenterID}
                key={presenter.userID}
              />
            )
          }
        })}
      </div>

    </React.Fragment>


  )
}
