import { Paper, Typography, Button } from '@material-ui/core';
import React, { ReactElement, useContext } from 'react';
import ErrorIcon from '@material-ui/icons/Warning';

import styles from './ErrorMessage.module.css';
import { ErrorMessageStyles } from './ErrorMessageStyles';
import { ErrorContext } from '../../../../Context/ErrorContext';

interface Props {
  errorMessage: string,
  errorStack: boolean,
  handleErrorClose: (errorMessage: string) => void,
}

export default function ErrorMessage(props: Props): ReactElement {

  const {
    setErrorMessages,
    errorMessages
  } = useContext(ErrorContext);

  //Refresh button
  const handleRefreshButton = () => {

  }

  //Close button
  const handleCloseButtonClick = () => {
    props.handleErrorClose(props.errorMessage);

  }

  const classes = ErrorMessageStyles();

  return (
    <Paper
      className={props.errorStack
        ? `errorInStack ${classes.errorMessageHolder}`
        : `errorInPopup ${classes.errorMessageHolder}`
      }
      style={{ position: props.errorStack ? "absolute" : "relative" }}
    >

      <div className={styles.errorMessageHolder}>

        <ErrorIcon className={classes.errorIcon} />

        <Typography variant="body1" className={classes.errorMessage}>
          {props.errorMessage}
        </Typography>


      </div>

      <Button
        variant="text"
        className={props.errorStack
          ? `closeButtonStack ${classes.closeButton}`
          : `closeButtonPopup ${classes.closeButton}`
        }
        onClick={handleCloseButtonClick}
      >
        CLOSE
      </Button>

      {/* <Button variant="text" className={classes.refreshButton}>
        REFRESH
      </Button> */}
    </ Paper>
  )
}
