import { Paper, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import StreamIcon from "@material-ui/icons/Cast";
import { ShowboatBridgeStyles } from './ShowboatBridgeStyles';
import styles from "./ShowboatBridge.module.css";

interface Props {
  setSelectedPage: (page: string) => any,
}

export default function ShowboatBridge(props: Props): ReactElement {

  const classes = ShowboatBridgeStyles();

  return (
    <React.Fragment>
      <Paper className={classes.showboatBridgeHolder}>

        <div className={styles.showboatBridgeLogoHolder}>
          <Typography variant="h1" className={classes.showboatBridgeHeader}>
            Showboat Bridge
          </Typography>

          <img
            className={styles.rectangle}
            alt="Logo rectangle"
            src="assets/images/sbBridge-rectangle.png"
          />
        </div>

      </Paper>

      <div className={styles.showboatBridgeSelectionsHolder}>
        {[...Array(8)].map((e, i) => {
          if (i === 0) {
            return (
              <Paper
                className={`${classes.showboatBridgeItem} ${classes.showboatBridgeItemStreamConnect}`}
                key={i}
                onClick={() => props.setSelectedPage("streamCenter")}
              >

                <StreamIcon
                  className={classes.showboatBridgeItemIcon}
                ></StreamIcon>

                <Typography variant="body1" className={classes.showboatBridgeItemHeader}>
                  STREAM CENTER
                </Typography>
              </Paper>
            )
          } else {
            return (
              <Paper
                className={classes.showboatBridgeItem}
                key={i}
              >

              </Paper>
            )
          }

        })}
      </div>

    </React.Fragment>
  )
}
