import { makeStyles, createStyles } from '@material-ui/core/styles';

const SlidesStyles = makeStyles((theme: any) => createStyles({
  backButton: {
    padding: 0,
    width: 116,
    height: 44,
    color: "white",
    backgroundColor: "#777777",
    position: "absolute",
    top: 0,
    left: 0,
  },
  nextButton: {
    padding: 0,
    width: 116,
    height: 44,
    color: "white",
    backgroundColor: "#777777",
    position: "absolute",
    top: 0,
    right: -8,
  },
  slideHeader: {
    color: "#FFCE00",
    left: 0,
    top: 6,
    fontWeight: "bold",
    fontSize: 14,
    position: "absolute"
  },
  presentationSelect: {
    position: "absolute",
    right: -7,
    top: 0,
    width: 274,
    textAlign: "left"
  },
  loadingSpinner: {
    color: "grey",
    position: "absolute",
    width: "30px !important",
    height: "30px !important",
    right: 300,
    top: 5,
  }
})
)

export { SlidesStyles };