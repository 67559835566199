
const DetectRTC = require('detectrtc');

const axios = require('axios');

enum JumbotronMode {
    off,
    slideShare,
    screenShare,
    localVideoShare,
    webcamShare,
    livestream,
    lsconnect
}


export class ServerHelper {

    public static UseDev: boolean = false;

    public static OnSocketIOLoginComplete: NSM.Delegate = new NSM.Delegate();

    public static OnForceScreenshareOff: NSM.Delegate = new NSM.Delegate();
    public static OnForceVideoshareOff: NSM.Delegate = new NSM.Delegate();
    public static OnForcePresenterWebcamOff: NSM.Delegate = new NSM.Delegate();
    public static OnForceSlideOff: NSM.Delegate = new NSM.Delegate();
    public static OnForceLivestreamOff: NSM.Delegate = new NSM.Delegate();
    public static OnForceShowboatConnectOff: NSM.Delegate = new NSM.Delegate();

    public static OnShowboatConnectModeChange: NSM.Delegate = new NSM.Delegate();

    public static OnServerConnectionTimeout: NSM.Delegate = new NSM.Delegate();
    public static loginCode: string;

    //Set up jumbotron class instances
    public static PreviewJumbotronVirtualScreenController: SHOWBOAT.VirtualScreenController;

    public static LiveJumbotronVirtualScreenController: SHOWBOAT.VirtualScreenController;

    //Set up Broadcast class instances
    public static PreviewJumbotronBroadcastMediaStream: SHOWBOAT.BroadcastMediaStream;
    public static LiveJumbotronBroadcastMediaStream: SHOWBOAT.BroadcastMediaStream;

    public static ScreenshareController: SHOWBOAT.PresenterScreenshare = new SHOWBOAT.PresenterScreenshare();
    public static LivestreamController: SHOWBOAT.LiveswitchMediaUpstreamController;

    public static loginFailReason: string;

    public static loginResult: SHOWBOAT.LoginResult;

    public static socketUserID: string;

    public static deadLoginCode: boolean = false;
    public static errorMsg: string;

    public static allowEarlyAccess: boolean = false;
    public static accessTime: string;

    public static hangUpURL: string;

    private static appAPIUrl = `https://hp4x6j53k0.execute-api.us-east-1.amazonaws.com/${process.env.REACT_APP_API_STAGE}`;

    public static showboatBridgeAPIUrl = `https://tof09wxucb.execute-api.us-east-1.amazonaws.com/${process.env.REACT_APP_API_STAGE}`;

    private static SocketIOServer: string;
    private static SocketIOPort: number;
    private static useSecureWebsockets = true;

    public static VideoServer: string;
    public static VideoServerAppID: string;

    private static connectionTimeout: NodeJS.Timeout;
    private static connectionTimeoutMilliseconds: number = 5000;

    private static connectionPromiseResolver: (boolean) => void;

    private static currentPresenterID: string;

    private static currentSlideNumber: number = 0;
    private static currentLivestreamURL: string = "";

    public static enableSupport: boolean = false;
    public static supportMessage: string = "";

    public static missingIntakeDataOnLogin: boolean = false;

    public static loginTimestamp: string;
    public static loginTime: Date;

    public static currentMode: JumbotronMode = JumbotronMode.off;

    public static screenshareID: number = 0;

    public static async Login(): Promise<SHOWBOAT.LoginResult> {


        //Listen for disconnects
        SHOWBOAT.SocketIOController.OnRemotePlayerDisconnected.Add(ServerHelper.OnRemotePlayerDisconnected);

        //Listen for socket IO presntation events
        SHOWBOAT.SocketIOController.OnEventVariableUpdate.Add("PRESENTER#Presentation", ServerHelper.OnPresenterVariableEvent);

        //Listen for screenshare ending
        ServerHelper.ScreenshareController.OnScreenshareStopped.Add(ServerHelper.OnScreenshareUpstreamStopped);

        //Listen for forceMutes from presenters in debug mode
        SHOWBOAT.SocketIOController.OnPrivateMessage.Add("ForceAudioMute", ServerHelper.onForceMuteMic);
        SHOWBOAT.SocketIOController.OnPrivateMessage.Add("UnforceAudioMute", ServerHelper.onUnforceMuteMic);
        SHOWBOAT.SocketIOController.OnRemotePlayerDataUpdate.Add(SHOWBOAT.RemoteAvatarDataManager.CHANGEREASON_ForceMute, ServerHelper.onForceMutePlayerDataUpdate);

        //UI Event Manager Helpers
        SHOWBOAT.UIEventManager.OnTeleportToStageToggle.Add(ServerHelper.OnTeleportToStageToggle);
        SHOWBOAT.UIEventManager.OnEventMuteToggle.Add(ServerHelper.OnEventMuteToggle);
        SHOWBOAT.UIEventManager.OnBroadcastToggle.Add(ServerHelper.OnBroadcastToggle);
        SHOWBOAT.UIEventManager.OnCoPresenterAudioChange.Add(ServerHelper.OnCoPresenterAudioChange);
        SHOWBOAT.UIEventManager.OnSlideToggle.Add(ServerHelper.OnSlideToggle);
        SHOWBOAT.UIEventManager.OnSlideChange.Add(ServerHelper.OnSlideChange);
        SHOWBOAT.UIEventManager.OnScreenshareToggle.Add(ServerHelper.OnScreenshareToggle);
        SHOWBOAT.UIEventManager.OnVideoshareToggle.Add(ServerHelper.OnVideoshareToggle)
        SHOWBOAT.UIEventManager.OnSilenceAudience.Add(ServerHelper.OnSilenceAudience);
        SHOWBOAT.UIEventManager.OnSpatialAudio.Add(ServerHelper.OnSpatialAudioChange);
        SHOWBOAT.UIEventManager.OnModeChange.Add(ServerHelper.OnModeChange);
        SHOWBOAT.UIEventManager.OnPresentWebcamToggle.Add(ServerHelper.OnPresentWebcamToggle);
        SHOWBOAT.UIEventManager.OnHLSToggle.Add(ServerHelper.OnHLSToggle);
        SHOWBOAT.UIEventManager.OnShowboatConnectToggle.Add(ServerHelper.OnShowboatConnectToggle);

        SHOWBOAT.UIEventManager.On3DAvatarLoadComplete.Add(ServerHelper.On3DAvatarLoadComplete);

        //AV Controller Listeners
        SHOWBOAT.StreamingUserMedia.OnCameraDeviceChanged.Add(ServerHelper.OnCameraDeviceChanged);

        //Setup axios to call the API
        axios.defaults.baseURL = ServerHelper.appAPIUrl;

        //Make login call  
        ServerHelper.loginCode = ServerHelper.getLoginCode();
        if (ServerHelper.loginCode.length == 0) {
            return ServerHelper.getErrorLoginResult("Missing Login Code");
        }

        if (ServerHelper.loginCode === "systemcheck") {
            return ServerHelper.getSystemCheckLoginResult();
        }

        const params = {
            loginCode: ServerHelper.loginCode,
            timestamp: new Date().getTime()
        };

        try {
            const loginApiResponse = await axios.get('login', { params });

            console.log("API RESPONSE", loginApiResponse);

            if (loginApiResponse && loginApiResponse.data) {
                if (loginApiResponse.data.success) {
                    let loginResult = loginApiResponse.data as SHOWBOAT.LoginResult;
                    ServerHelper.loginResult = loginResult;
                    ServerHelper.storeLoginResultData(loginResult);
                    ServerHelper.loginFailReason = loginResult.failReason;
                    return loginResult;
                } else if (!loginApiResponse.data.success && loginApiResponse.data.failReason === "DeletedLoginCode") {
                    let loginResult = loginApiResponse.data as SHOWBOAT.LoginResult;
                    ServerHelper.loginResult = loginResult;
                    ServerHelper.storeLoginResultData(loginResult);
                    ServerHelper.loginFailReason = loginResult.failReason
                    ServerHelper.errorMsg = loginResult.errorMsg;
                    return loginResult;
                } else if (!loginApiResponse.data.success &&
                    (loginApiResponse.data.failReason === "NotStarted" || loginApiResponse.data.failReason === "Ended"
                        || loginApiResponse.data.failReason === "CapacityFull")) {
                    let loginResult = loginApiResponse.data as SHOWBOAT.LoginResult;
                    ServerHelper.loginResult = loginResult;
                    ServerHelper.storeLoginResultData(loginResult);
                    ServerHelper.deadLoginCode = false;
                    ServerHelper.loginFailReason = loginResult.failReason;
                    return loginResult;
                } else {
                    return ServerHelper.getErrorLoginResult("Login unsuccesful");
                }

            } else {
                return ServerHelper.getErrorLoginResult("Login result failed");
            }

        } catch (err) {
            console.log(err);
            return ServerHelper.getErrorLoginResult("Missing Login Code");
        }

    }

    private static getLoginCode(): string {
        //Get the code the person is attempting to login in with
        let loginCode = ServerHelper.GetQueryParam("login");

        //Check if we got a login code. If not, try to parse it from the URL
        if (!loginCode || loginCode.length === 0 || loginCode === null) {
            //loginCode = window.location.pathname.split("/").pop();
            loginCode = window.location.pathname;

            let searchResult = loginCode.search(/^\/[a-zA-Z0-9]*$/);

            if (searchResult < 0 || !loginCode || loginCode.length === 0 || loginCode === null) {
                loginCode = "devTest";
            } else {
                loginCode = loginCode.substring(1);
            }

        }


        console.log("loginCode", loginCode);

        return loginCode;

    }

    private static GetQueryParam = (paramName: string) => {
        paramName = paramName.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + paramName + '=([^&#]*)');
        var results = regex.exec(window.location.search);
        return (results !== null) ? decodeURIComponent(results[1].replace(/\+/g, ' ')) : null;
    };

    private static getSystemCheckLoginResult = () => {
        let loginResult: SHOWBOAT.LoginResult = {
            success: false,
            allowEarlyAccess: false,
            failReason: "SystemCheck",
            role: "",
            registrationData: null,
            bookingName: "",
            startTime: "",
            endTime: "",
            accessTime: "",
            uiSkin: {},
            avatarSkin: {},
            worldSkin: {},
            avatar: {},
            world: {},
            userData: {},
            eventID: "",
            gameServer: "",
            gamePort: 0,
            videoServer: "",
            videoServerAppID: "",
            enableSupport: false,
            supportMessage: "",
            hangUpURL: ""
        }

        //Set "SystemCheck" fail reason
        ServerHelper.loginFailReason = "SystemCheck";

        return loginResult;
    }


    private static getErrorLoginResult(failReason: string): SHOWBOAT.LoginResult {
        let loginResult: SHOWBOAT.LoginResult = {
            success: false,
            allowEarlyAccess: false,
            failReason: failReason,
            role: "",
            registrationData: null,
            bookingName: "",
            startTime: "",
            endTime: "",
            accessTime: "",
            uiSkin: {},
            avatarSkin: {},
            worldSkin: {},
            avatar: {},
            world: {},
            userData: {},
            eventID: "",
            gameServer: "",
            gamePort: 0,
            videoServer: "",
            videoServerAppID: "",
            enableSupport: false,
            supportMessage: ""
           /*  applicationSkin : null */,
            hangUpURL: ""

        }
        return loginResult;
    }

    private static storeLoginResultData(loginResult: SHOWBOAT.LoginResult) {

        if (loginResult.success ||
            //also set skinnable properties for error states where we need them
            (loginResult.failReason === "NotStarted" || loginResult.failReason === "Ended"
                || loginResult.failReason === "CapacityFull" || loginResult.failReason === "DeletedLoginCode")) {

            //Socket information
            ServerHelper.SocketIOServer = loginResult.gameServer;
            ServerHelper.SocketIOPort = loginResult.gamePort;

            //Liveswitch server to connect to
            ServerHelper.VideoServer = loginResult.videoServer;

            if (loginResult.videoServerAppID) {
                ServerHelper.VideoServerAppID = loginResult.videoServerAppID;
            } else {
                ServerHelper.VideoServerAppID = SHOWBOAT.ApplicationSkin.applicationID;
            }

            /* USER DATA */
            SHOWBOAT.LocalAvatarDataManager.partition = "attendees";
            SHOWBOAT.LocalAvatarDataManager.role = loginResult.role;

            /* EVENT DATA */
            if (loginResult.bookingName) {
                SHOWBOAT.ApplicationSkin.eventName = loginResult.bookingName;
            }

            /* EARLY ACCESS DATA*/
            if (loginResult.allowEarlyAccess) {
                ServerHelper.allowEarlyAccess = loginResult.allowEarlyAccess;
            }
            if (loginResult.accessTime) {
                ServerHelper.accessTime = loginResult.accessTime;
            }

            /* UI Skin */
            if (loginResult.uiSkin && loginResult.uiSkin.theme) {
                SHOWBOAT.ApplicationSkin.theme = loginResult.uiSkin.theme;
                console.log("THEME", SHOWBOAT.ApplicationSkin.theme);
            }

            if (loginResult.uiSkin && loginResult.uiSkin.landingPageAssetURL) {
                SHOWBOAT.ApplicationSkin.landingPageGraphicURL = loginResult.uiSkin.landingPageAssetURL;
            }

            if (loginResult.uiSkin && loginResult.uiSkin.landingPageGraphicType) {
                SHOWBOAT.ApplicationSkin.landingPageGraphicType = loginResult.uiSkin.landingPageGraphicType;
            }

            if (loginResult.uiSkin && loginResult.uiSkin.thumbnailLogoURL) {
                SHOWBOAT.ApplicationSkin.landingPageThumbnailURL = loginResult.uiSkin.thumbnailLogoURL;
            }

            if (loginResult.uiSkin && loginResult.uiSkin.intakeFields) {
                SHOWBOAT.ApplicationSkin.intakeFields = loginResult.uiSkin.intakeFields;
            }

            if (loginResult.uiSkin && loginResult.uiSkin.primaryThemeColor) {
                SHOWBOAT.ApplicationSkin.primaryThemeColor = loginResult.uiSkin.primaryThemeColor;
            }

            /* Avatar Skin */
            if (loginResult.avatarSkin) {
                //Colors
                if (loginResult.avatarSkin.avatarColors && loginResult.avatarSkin.avatarColors.type == "ColorPairList") {
                    let primaryColors: string[] = [];
                    let secondaryColors: string[] = [];
                    for (let i = 0; i < loginResult.avatarSkin.avatarColors.value.length; ++i) {
                        let colorRow: any = loginResult.avatarSkin.avatarColors.value[i];
                        primaryColors.push(colorRow.primary);
                        secondaryColors.push(colorRow.secondary);
                    }
                    if (primaryColors.length > 0 && primaryColors.length == secondaryColors.length) {

                        SHOWBOAT.ApplicationSkin.primaryAvatarColors = primaryColors;
                        SHOWBOAT.ApplicationSkin.secondaryAvatarColors = secondaryColors;
                    }
                }

                //Thumbnails
                if (loginResult.avatarSkin.thumbnails && loginResult.avatarSkin.thumbnails.value) {
                    SHOWBOAT.ApplicationSkin.faceThumbnailURLs = loginResult.avatarSkin.thumbnails.value;
                }

                //TextureMap
                if (loginResult.avatarSkin.textureMap && loginResult.avatarSkin.textureMap.value) {
                    SHOWBOAT.ApplicationSkin.avatarTextureMap = loginResult.avatarSkin.textureMap.value;
                }
            }

            /* WORLD SKIN */
            if (loginResult.worldSkin) {
                SHOWBOAT.ApplicationSkin.worldSkin = loginResult.worldSkin;
            }

            /*MODEL FILES*/
            if (loginResult.world && loginResult.world.modelFile) {
                SHOWBOAT.ApplicationSkin.spaceModelURL = loginResult.world.modelFile;
            }

            if (loginResult.world && loginResult.world.sourceFile) {
                SHOWBOAT.ApplicationSkin.babylonToolkitBundlePath = loginResult.world.sourceFile;
            }

            /*
            if(loginResult.world && loginResult.world.sourceFile && loginResult.world.sourceFile[0]){
                SHOWBOAT.ApplicationSkin.babylonToolkitBundlePath = loginResult.world.sourceFile[0];
            }
            */

            if (loginResult.avatar && loginResult.avatar.modelFile) {
                SHOWBOAT.ApplicationSkin.avatarModelURL = loginResult.avatar.modelFile;
            }




            if (loginResult.startTime) {
                let startDateTime = new Date(loginResult.startTime);
                console.log(startDateTime.toLocaleString());
                console.log(startDateTime);

                const day = startDateTime.getDate() + (startDateTime.getDate() % 10 == 1 && startDateTime.getDate() != 11 ? 'st' : (startDateTime.getDate() % 10 == 2 && startDateTime.getDate() != 12 ? 'nd' : (startDateTime.getDate() % 10 == 3 && startDateTime.getDate() != 13 ? 'rd' : 'th')));
                const month = startDateTime.toLocaleString('default', { month: 'long' });
                const year = startDateTime.toLocaleString('default', { year: 'numeric' });
                const time = startDateTime.toLocaleString('default', { hour: 'numeric', minute: 'numeric' });

                const dateString = `${month} ${day}, ${year} - ${time}`;
                SHOWBOAT.ApplicationSkin.eventTime = dateString;

                SHOWBOAT.ApplicationSkin.startDateTime = loginResult.startTime;

            }


            if (loginResult.timestamp) {
                ServerHelper.loginTimestamp = loginResult.timestamp;
                ServerHelper.loginTime = new Date(loginResult.timestamp);
                console.log("ServerHelper.loginTime", ServerHelper.loginTime);
            }


            if (loginResult.registrationData) {
                /*** INTAKE DATA  ****/
                SHOWBOAT.LocalAvatarDataManager.registrationData = loginResult.registrationData;


                /*** Local Avatar Data ***/

                if (loginResult.registrationData.firstName !== undefined) {
                    console.log("FIRST", loginResult.registrationData.firstName);
                    SHOWBOAT.LocalAvatarDataManager.firstName = loginResult.registrationData.firstName;
                }
                if (loginResult.registrationData.lastName !== undefined) {
                    SHOWBOAT.LocalAvatarDataManager.lastName = loginResult.registrationData.lastName;
                }
                if (loginResult.registrationData.company !== undefined) {
                    SHOWBOAT.LocalAvatarDataManager.company = loginResult.registrationData.company;
                }

                if (Object.keys(loginResult.registrationData).length === 0) {

                    console.log("Missing intake data on login code");

                    //Mark that there is no registration data on the login code
                    ServerHelper.missingIntakeDataOnLogin = true;
                }

            }

            if (loginResult.hangUpURL) {
                ServerHelper.hangUpURL = loginResult.hangUpURL;
            }

            if (loginResult.eventID) {
                SHOWBOAT.LocalAvatarDataManager.eventID = loginResult.eventID;
            }

            if (loginResult.role === 'presenter') {

                //ADD USERDATA PRESENTATION STUFF TO APPLICATION SKIN

                if (loginResult.userData.slides) {
                    SHOWBOAT.ApplicationSkin.slides = loginResult.userData.slides;
                }
            }

            if (loginResult.enableSupport) {
                ServerHelper.enableSupport = loginResult.enableSupport;
            }

            if (loginResult.supportMessage) {
                ServerHelper.supportMessage = loginResult.supportMessage;
            }
        }
    }

    public static Connect = (): Promise<boolean> => {

        return new Promise((resolve, reject) => {

            ServerHelper.connectionPromiseResolver = resolve;

            //Sign up to listen for connection event
            SHOWBOAT.SocketIOController.OnLocalPlayerConnected.Add(ServerHelper.OnLocalPlayerConnected);
            SHOWBOAT.SocketIOController.OnLocalPlayerDisconnected.Add(ServerHelper.OnLocalPlayerDisconnected);
            SHOWBOAT.SocketIOController.OnLocalPlayerReconnected.Add(ServerHelper.OnLocalPlayerReconnected);

            //Make the connection with the socket
            SHOWBOAT.SocketIOController.OnConnectionTimeout.Add(ServerHelper.OnConnectionTimeout);

            //Set a timeout to watch this operation is not taking too long
            ServerHelper.connectionTimeout = setTimeout(ServerHelper.OnConnectionTimeout, ServerHelper.connectionTimeoutMilliseconds);

            //Connect the socket
            SHOWBOAT.SocketIOController.Connect(ServerHelper.SocketIOServer, ServerHelper.SocketIOPort, ServerHelper.useSecureWebsockets);

            //Set frame rate
            SHOWBOAT.SocketIOController.SetWorkerTransformUpdateInterval(66);

        });

    }

    private static OnLocalPlayerConnected = () => {

        //Clear any existing timeout
        clearTimeout(ServerHelper.connectionTimeout);

        //Remove ourself from listening for socket IO timeout event
        SHOWBOAT.SocketIOController.OnConnectionTimeout.Remove(ServerHelper.OnConnectionTimeout);

        //Remove connection listener
        SHOWBOAT.SocketIOController.OnLocalPlayerConnected.Remove(ServerHelper.OnLocalPlayerConnected);

        //make sure no conenction errors are showing
        SHOWBOAT.UIEventManager.OnServerConnectionErrorToggle.Raise(false);

        //Notify the promise that we are complete
        ServerHelper.connectionPromiseResolver(true);

    }


    private static OnLocalPlayerDisconnected = () => {
        SHOWBOAT.UIEventManager.OnServerConnectionErrorToggle.Raise(true);
    }

    private static OnLocalPlayerReconnected = (avatarDatas: SHOWBOAT.AvatarData[]) => {
        SHOWBOAT.UIEventManager.OnServerConnectionErrorToggle.Raise(false);

        ServerHelper.handleReconnect();

    }

    private static async handleReconnect() {

        try {

            let avatarDatas: SHOWBOAT.AvatarData[] = await SHOWBOAT.SocketIOController.RequestAllPlayerData();

            //Check for players that have joined
            let tempReconnectMap: Map<string, SHOWBOAT.AvatarData> = new Map<string, SHOWBOAT.AvatarData>();
            for (let i = 0; i < avatarDatas.length; ++i) {
                let avatarData = avatarDatas[i];
                tempReconnectMap.set(avatarData.userID, avatarData);

                //Check if we know about this avatar
                let storedAvatarData = SHOWBOAT.RemoteAvatarDataManager.getAvatarData(avatarData.userID);
                if (storedAvatarData) {
                    //We already know about this person

                    //Update the map in case avatarData changed
                    SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDataUpdateHandler(avatarData);

                    //Check for partition change
                    if (storedAvatarData.partition != avatarData.partition) {
                        SHOWBOAT.SocketIOController.OnPartitionChange.Raise(avatarData);
                    }

                    //Check for a room change
                    if (storedAvatarData.roomID != avatarData.roomID) {
                        SHOWBOAT.SocketIOController.OnRemotePlayerRoomChange.Raise(storedAvatarData.roomID, avatarData);
                    }

                    //Check for change to camera and mic
                    if (storedAvatarData.cameraEnabled != avatarData.cameraEnabled && storedAvatarData.micEnabled != avatarData.micEnabled) {
                        SHOWBOAT.SocketIOController.OnRemotePlayerDataUpdate.Raise(SHOWBOAT.RemoteAvatarDataManager.CHANGEREASON_CAMERAANDMIC, avatarData);
                    } else if (storedAvatarData.cameraEnabled != avatarData.cameraEnabled) {
                        SHOWBOAT.SocketIOController.OnRemotePlayerDataUpdate.Raise(SHOWBOAT.RemoteAvatarDataManager.CHANGEREASON_CAMERA, avatarData);
                    } else if (storedAvatarData.micEnabled != avatarData.micEnabled) {
                        SHOWBOAT.SocketIOController.OnRemotePlayerDataUpdate.Raise(SHOWBOAT.RemoteAvatarDataManager.CHANGEREASON_MIC, avatarData);
                    }

                    //Check laser
                    if (storedAvatarData.laserEnabled != avatarData.laserEnabled) {
                        SHOWBOAT.SocketIOController.OnRemotePlayerDataUpdate.Raise(SHOWBOAT.RemoteAvatarDataManager.CHANGEREASON_LASERENABLED, avatarData);
                    }

                    //Force mute
                    if (storedAvatarData.isForceMuted != avatarData.isForceMuted) {
                        SHOWBOAT.SocketIOController.OnRemotePlayerDataUpdate.Raise(SHOWBOAT.RemoteAvatarDataManager.CHANGEREASON_ForceMute, avatarData);
                    }

                    //Load complete
                    if (storedAvatarData.loadComplete != avatarData.loadComplete) {
                        SHOWBOAT.SocketIOController.OnRemotePlayerDataUpdate.Raise(SHOWBOAT.RemoteAvatarDataManager.CHANGEREASON_LoadComplete, avatarData);
                    }

                    //Device Debug
                    if (storedAvatarData.currentMicName != avatarData.currentMicName ||
                        storedAvatarData.currentCameraName != avatarData.currentCameraName ||
                        storedAvatarData.currentSpeakerName != avatarData.currentSpeakerName ||
                        storedAvatarData.micDeviceList != avatarData.micDeviceList ||
                        storedAvatarData.cameraDeviceList != avatarData.cameraDeviceList ||
                        storedAvatarData.speakerDeviceList != avatarData.speakerDeviceList
                    ) {
                        SHOWBOAT.SocketIOController.OnRemotePlayerDataUpdate.Raise(SHOWBOAT.RemoteAvatarDataManager.CHANGEREASON_DeviceDebug, avatarData);
                    }


                } else {
                    //We don't know about this person
                    SHOWBOAT.SocketIOController.OnRemotePlayerConnected.Raise(avatarData);
                }
            }

            //Check for any avatars that have left
            let avatarList = SHOWBOAT.RemoteAvatarDataManager.getAllAvatarsInEvent(SHOWBOAT.LocalAvatarDataManager.userID);
            for (let i = 0; i < avatarList.length; ++i) {
                //Check if this player still existed upon reconnect
                if (!tempReconnectMap.has(avatarList[i].userID)) {

                    SHOWBOAT.SocketIOController.OnRemotePlayerDisconnected.Remove(ServerHelper.OnRemotePlayerDisconnected); //Temporaily remove self as a responder to this to avoid refetching this list
                    SHOWBOAT.SocketIOController.OnRemotePlayerDisconnected.Raise(avatarList[i]);
                    SHOWBOAT.UIEventManager.OnLeftNearbyList.Raise(avatarList[i].userID);
                    SHOWBOAT.SocketIOController.OnRemotePlayerDisconnected.Add(ServerHelper.OnRemotePlayerDisconnected);   //Add self back as a responder

                }
            }

        } catch (err) {
            SHOWBOAT.Logger.Error("Error retrieving avatar data after socket reconnection.");
            SHOWBOAT.Logger.Error(err);
        }
    }

    public static OnEnterStreamCenter = async () => {
        //Execute logic to be completed after leaving landing page

        //Resume AudioContext
        SHOWBOAT.AudioContextManager.AudioContext.resume();

        //Switch role to brain
/*         SHOWBOAT.LocalAvatarDataManager.role = "brain";
 */
        //Connect to socket
        let socketConnect = await ServerHelper.Connect();

        if (socketConnect) {
            //Login to socket
            let socketLoginResult = await SHOWBOAT.SocketIOController.Login(
                ServerHelper.loginCode,
                SHOWBOAT.LocalAvatarDataManager.avatarData
            );

            console.log("AAAA", socketLoginResult);

            //Check socket result before registering to liveswitch
            if (socketLoginResult.success) {
                //Set socketID on LocalAvatarDataManager
                SHOWBOAT.LocalAvatarDataManager.userID = socketLoginResult.userID;

                //Get avatar data array
                try {
                    let avatarDataArray: SHOWBOAT.AvatarData[] = [];

                    avatarDataArray = await SHOWBOAT.SocketIOController.RequestAllPlayerData();

                    SHOWBOAT.RemoteAvatarDataManager.setInitialAvatarData(avatarDataArray);
                } catch (error) {
                    SHOWBOAT.Logger.Error("Error retrieving participant data from Showboat application server.");
                }

                return true;

            } else {
                return false;
            }
        } else {
            return false;
        }

    }

    public static registerToLiveswitch = async () => {
        try {
            await SHOWBOAT.LiveSwitchClientController.createClient(
                ServerHelper.loginResult.videoServerAppID,
                SHOWBOAT.LocalAvatarDataManager.userID,
                `${Date.now().valueOf() + Math.random()}`,
                "ShowboatWebClient_" + SHOWBOAT.LocalAvatarDataManager.eventID,
                ServerHelper.loginResult.videoServer
            );

            //Register to liveswitch
            await SHOWBOAT.LiveSwitchClientController.register();

            return true;
        }
        catch {
            //Execute fail logic
            return false;
        }
    }



    private static OnConnectionTimeout = () => {

        //Clear any existing timeout
        clearTimeout(ServerHelper.connectionTimeout);

        //Remove ourself from listening for socket IO timeout event
        SHOWBOAT.SocketIOController.OnConnectionTimeout.Remove(ServerHelper.OnConnectionTimeout);

        console.log("Initial socket connection timed out");

        //Raise the timeout event
        ServerHelper.OnServerConnectionTimeout.Raise();

        ServerHelper.connectionPromiseResolver(false);
    }




    public static OnTeleportToStageToggle(toStage: boolean): void {
    }

    public static OnEventMuteToggle(isEventMuted: boolean): void {
        SHOWBOAT.SocketIOController.SendEventAll("PRESENTER#EventMute", { isEventMuted: isEventMuted });

    }

    public static OnBroadcastToggle(isBroadcasting: boolean): void {

    }

    public static OnCoPresenterAudioChange(volumeLevel: number): void {

    }



    public static switchToJumbotronMode(toJumbotronMode: JumbotronMode, requestiongJumbotronMode = null): void {

        console.log("switchToJumbotronMode", toJumbotronMode, requestiongJumbotronMode);

        if (requestiongJumbotronMode == null) {
            requestiongJumbotronMode = toJumbotronMode;
        }

        //Ensure the requesting mode is the current mode for any off requests
        if (toJumbotronMode == JumbotronMode.off && requestiongJumbotronMode != ServerHelper.currentMode) return;

        //Swap modes
        let previousMode = ServerHelper.currentMode;
        ServerHelper.currentMode = toJumbotronMode;

        //Check if we are leaving a mode
        if (previousMode != ServerHelper.currentMode) {
            switch (previousMode) {
                case JumbotronMode.off:
                    //nothing to do
                    break;

                case JumbotronMode.slideShare:
                    ServerHelper.OnForceSlideOff.Raise();
                    break;

                case JumbotronMode.screenShare:
                    ServerHelper.OnForceScreenshareOff.Raise();
                    break;

                case JumbotronMode.localVideoShare:
                    ServerHelper.OnForceVideoshareOff.Raise();
                    break;

                case JumbotronMode.webcamShare:
                    ServerHelper.OnForcePresenterWebcamOff.Raise();
                    break;

                case JumbotronMode.livestream:
                    ServerHelper.OnForceLivestreamOff.Raise();
                    break;

                case JumbotronMode.lsconnect:
                    ServerHelper.OnForceShowboatConnectOff.Raise();
                    break;
            }
        }

        //Switch to the correct mode
        switch (ServerHelper.currentMode) {
            case JumbotronMode.off:

                //If we were the current presenter, turn jumbotron off
                //also allow any presenter to disable LSConnect
                if (ServerHelper.currentPresenterID == SHOWBOAT.LocalAvatarDataManager.userID || previousMode == JumbotronMode.lsconnect) {
                    SHOWBOAT.SocketIOController.SetServerEventVariable('PRESENTER#Presentation', {
                        mode: 'off',
                        slide: "",
                        userID: SHOWBOAT.LocalAvatarDataManager.avatarData.userID
                    });
                }

                break;

            case JumbotronMode.slideShare:

                SHOWBOAT.SocketIOController.SetServerEventVariable("PRESENTER#Presentation", {
                    mode: "slide",
                    slide: SHOWBOAT.ApplicationSkin.slides.presentationSlides[ServerHelper.currentSlideNumber],
                    userID: SHOWBOAT.LocalAvatarDataManager.avatarData.userID
                });

                break;

            case JumbotronMode.screenShare:

                //Ensure not already in this mode
                if (previousMode == JumbotronMode.screenShare) return;

                //Notify socket of the screenshare
                SHOWBOAT.SocketIOController.SetServerEventVariable('PRESENTER#Presentation', {
                    mode: 'screenshare',
                    userID: SHOWBOAT.LocalAvatarDataManager.avatarData.userID,
                    screenshareID: ServerHelper.screenshareID
                });

                break;

            case JumbotronMode.localVideoShare:

                //Ensure not already in this mode
                if (previousMode == JumbotronMode.localVideoShare) return;

                //Notify socket of the screenshare
                SHOWBOAT.SocketIOController.SetServerEventVariable('PRESENTER#Presentation', {
                    mode: 'screenshare',
                    userID: SHOWBOAT.LocalAvatarDataManager.avatarData.userID,
                    screenshareID: ServerHelper.screenshareID
                });

                break;

            case JumbotronMode.webcamShare:

                //Ensure not already in this mode
                if (previousMode == JumbotronMode.webcamShare) return;

                SHOWBOAT.SocketIOController.SetServerEventVariable('PRESENTER#Presentation', {
                    mode: 'screenshare',
                    userID: SHOWBOAT.LocalAvatarDataManager.avatarData.userID,
                    screenshareID: ServerHelper.screenshareID
                });

                break;

            case JumbotronMode.livestream:

                //Ensure not already in this mode
                if (previousMode == JumbotronMode.livestream) return;

                console.log("URL", ServerHelper.currentLivestreamURL);

                SHOWBOAT.SocketIOController.SetServerEventVariable('PRESENTER#Presentation', {
                    mode: 'livestream',
                    url: ServerHelper.currentLivestreamURL,
                    userID: SHOWBOAT.LocalAvatarDataManager.avatarData.userID
                });

                break;

            case JumbotronMode.lsconnect:

                //Ensure not already in this mode
                if (previousMode == JumbotronMode.lsconnect) {
                    console.log("already in lsconnect mode");
                }

                SHOWBOAT.SocketIOController.SetServerEventVariable('PRESENTER#Presentation', {
                    mode: 'lsconnect',
                    userID: SHOWBOAT.LocalAvatarDataManager.avatarData.userID
                });

                break;

        }



    }

    public static ShowDefaultAssetOnLive = () => {

        //Check if default is an image or video
        if (SHOWBOAT.ApplicationSkin.worldSkin.jumboTronDefaultImage.type === "Image") {
            let defaultImg = document.createElement("img");
            defaultImg.src = SHOWBOAT.ApplicationSkin.worldSkin.jumboTronDefaultImage.value;
            defaultImg.id = "defaultImage";
            defaultImg.crossOrigin = "anonymous";

            ServerHelper.LiveJumbotronBroadcastMediaStream.addDrawableElement(
                "defaultImage",
                defaultImg,
                true,
                true,
                0,
                0,
                960,
                540
            );
        } else if (SHOWBOAT.ApplicationSkin.worldSkin.jumboTronDefaultImage.type === "Video") {
            let defaultVideo = document.createElement("video");
            defaultVideo.src = SHOWBOAT.ApplicationSkin.worldSkin.jumboTronDefaultImage.value;
            defaultVideo.id = "defaultVideo";
            defaultVideo.crossOrigin = "anonymous";

            defaultVideo.play();
            defaultVideo.loop = true;

            ServerHelper.LiveJumbotronBroadcastMediaStream.addVideoElement(
                "defaultVideo",
                defaultVideo,
                true,
                true,
                0,
                0
            )
        } else {
            //TODO: 
            // Handle other asset types
        }

    }



    public static OnSlideToggle(slideModeIsOn: boolean, currentSlide: number = 0): void {

        console.log("OnSlideToggle", slideModeIsOn, currentSlide);

        if (slideModeIsOn) {
            ServerHelper.currentSlideNumber = currentSlide;
            ServerHelper.switchToJumbotronMode(JumbotronMode.slideShare);
        } else {
            ServerHelper.switchToJumbotronMode(JumbotronMode.off, JumbotronMode.slideShare);
        }



        /*
        if (slideModeIsOn) {  
            
            ServerHelper.OnSlideChange(currentSlide);          

        } else {

            ServerHelper.isSlideOn = false;
            
            if (ServerHelper.isScreenOn || ServerHelper.isVideoOn || ServerHelper.isPresenterWebCamOn) {     
                //if screenshare is on, don't do anything           
                return;
            } else {
                //tell everyone turn off all presentation material if we are the current presenter

                if(ServerHelper.currentPresenterID == SHOWBOAT.LocalAvatarDataManager.userID){
                    SHOWBOAT.SocketIOController.SetServerEventVariable('PRESENTER#Presentation', {
                        mode: 'off',
                        slide: "",
                        userID: SHOWBOAT.LocalAvatarDataManager.avatarData.userID
                    });
                }

            }
        }
        */

    }

    public static OnSlideChange(slideNumber: number): void {

        console.log("OnSlideChange", slideNumber);

        //ensure this slide exists
        if (SHOWBOAT.ApplicationSkin.slides.presentationSlides[slideNumber] === undefined) {
            return;
        }

        //Ensure we are in slide share mode for this request to be valid
        if (ServerHelper.currentMode == JumbotronMode.slideShare) {
            ServerHelper.currentSlideNumber = slideNumber;
            ServerHelper.switchToJumbotronMode(JumbotronMode.slideShare);
        }


    }

    public static async OnScreenshareToggle(wantScreenshareOn: boolean): Promise<boolean> {

        console.log("ServerHelper.OnScreenshareToggle", wantScreenshareOn);

        //No need to change if already in this state
        if (wantScreenshareOn && ServerHelper.currentMode == JumbotronMode.screenShare) return true;
        if (!wantScreenshareOn && ServerHelper.currentMode == JumbotronMode.off) return true;

        if (wantScreenshareOn) {

            try {

                //Check if we need to stop a current version
                if (ServerHelper.ScreenshareController.isScreenSharing()) {
                    await ServerHelper.ScreenshareController.stopScreenshare();
                }

                //increment the identifier
                ++ServerHelper.screenshareID;

                let startScreenshareResult = await ServerHelper.ScreenshareController.startScreenshare();
                if (startScreenshareResult) {

                    //Ensure volume starts at 1 incase audio was previously lower
                    SHOWBOAT.SocketIOController.SetServerEventVariable("PRESENTER#LocalVideoVolume", { volume: 1 });

                    ServerHelper.switchToJumbotronMode(JumbotronMode.screenShare);

                } else {
                    ServerHelper.OnForceScreenshareOff.Raise();
                }

            } catch (error) {
                console.log("ServerHelper.OnScreenshareToggle", "OnScreenshareToggle error", wantScreenshareOn);
                console.log(error);
                return false;
            }

        } else {

            //Stop the stream
            if (ServerHelper.currentMode == JumbotronMode.screenShare) {
                await ServerHelper.ScreenshareController.stopScreenshare();;
            }

            ServerHelper.switchToJumbotronMode(JumbotronMode.off, JumbotronMode.screenShare);
        }

        return true;
    }


    private static async OnVideoshareToggle(wantVideoShareOn: boolean): Promise<boolean> {

        console.log("ServerHelper.OnVideoshareToggle", wantVideoShareOn);

        //No need to chang if already in this state
        if (wantVideoShareOn && ServerHelper.currentMode == JumbotronMode.localVideoShare) return true;
        if (!wantVideoShareOn && ServerHelper.currentMode == JumbotronMode.off) return true;

        if (wantVideoShareOn) {

            try {
                //Check if we need to stop a current version
                if (ServerHelper.ScreenshareController.isScreenSharing()) {
                    await ServerHelper.ScreenshareController.stopScreenshare();
                }

                //increment the identifier
                ++ServerHelper.screenshareID;

                let startVideoshareResult = await SHOWBOAT.LiveswitchScreenshareUpstreamController.startShare(SHOWBOAT.ShareType.VideoShare, SHOWBOAT.LocalAvatarDataManager.userID, ServerHelper.screenshareID.toString(), "localVideo");
                if (startVideoshareResult) {
                    ServerHelper.switchToJumbotronMode(JumbotronMode.localVideoShare);
                } else {
                    ServerHelper.OnForceVideoshareOff.Raise();
                }

            } catch (error) {
                console.log("ServerHelper.OnVideoshareToggle", "OnVideoshareToggle error", wantVideoShareOn);
                console.log(error);
                return false;
            }

        } else {
            //Stop the stream
            if (ServerHelper.currentMode == JumbotronMode.localVideoShare) {
                ServerHelper.ScreenshareController.stopScreenshare();
            }
            ServerHelper.switchToJumbotronMode(JumbotronMode.off, JumbotronMode.localVideoShare);
        }

        return true;
    }

    private static async OnPresentWebcamToggle(isPresenterWebCamOn: boolean) {

        console.log("OnPresentWebcamToggle", isPresenterWebCamOn);



        //No need to chang if already in this state
        if (isPresenterWebCamOn && ServerHelper.currentMode == JumbotronMode.webcamShare) return;
        if (!isPresenterWebCamOn && ServerHelper.currentMode == JumbotronMode.off) return;

        if (isPresenterWebCamOn) {

            //No point do anything if the camera is off
            if (!SHOWBOAT.StreamingUserMedia.isCameraRunning()) return;

            try {
                //Upgrade to a 720p stream
                await SHOWBOAT.StreamingUserMedia.SetHDMode(true);

                ++ServerHelper.screenshareID;

                let startWebCamShareResult = await SHOWBOAT.LiveswitchScreenshareUpstreamController.startShare(SHOWBOAT.ShareType.WebCamShare, SHOWBOAT.LocalAvatarDataManager.userID, ServerHelper.screenshareID.toString());
                if (startWebCamShareResult) {
                    ServerHelper.switchToJumbotronMode(JumbotronMode.webcamShare);
                } else {
                    ServerHelper.OnForcePresenterWebcamOff.Raise();
                }

            } catch (err) {
                SHOWBOAT.Logger.Error("Error starting webcam share");
                SHOWBOAT.Logger.Error(err);
            }

        } else {

            await SHOWBOAT.StreamingUserMedia.SetHDMode(false);

            //Stop the stream
            if (ServerHelper.currentMode == JumbotronMode.webcamShare) {
                ServerHelper.ScreenshareController.stopScreenshare();
            }

            ServerHelper.switchToJumbotronMode(JumbotronMode.off, JumbotronMode.webcamShare);
        }


    }

    private static OnHLSToggle = (isLivestreamOn: boolean, url: string = "") => {

        ServerHelper.currentLivestreamURL = url;

        if (isLivestreamOn) {
            ServerHelper.switchToJumbotronMode(JumbotronMode.livestream);
        } else {
            ServerHelper.switchToJumbotronMode(JumbotronMode.off, JumbotronMode.livestream);
        }



    }

    private static OnCameraDeviceChanged() {

        //Turn off webcam share if we change cameras
        if (ServerHelper.currentMode == JumbotronMode.webcamShare) {
            ServerHelper.OnPresentWebcamToggle(false);
        }

    }


    private static OnScreenshareUpstreamStopped(): void {
        ServerHelper.OnScreenshareToggle(false);
        //ServerHelper.OnForceScreenshareOff.Raise();
    }

    public static OnPresenterVariableEvent(newValue: any): void {

        //Set the current presenter ID so we never accidently turn off while we are not the last setter of this value
        if (newValue.userID) {
            ServerHelper.currentPresenterID = newValue.userID;
        } else {
            ServerHelper.currentPresenterID = "";
        }


        //Check if we need to disable our own screenshare
        if (newValue.mode) {

            //Check if the screensharer is someone other than ourself
            if (newValue.userID && newValue.userID != SHOWBOAT.LocalAvatarDataManager.userID) {

                switch (newValue.mode) {
                    case "lsconnect":
                        //Mark our current mode is lsconnect since anyone can deactivate this mode
                        ServerHelper.currentMode = JumbotronMode.lsconnect;
                    case "screenshare":
                    case "slide":
                    case "livestream":

                        //Check if we should turn off our screenshare
                        if (ServerHelper.currentMode == JumbotronMode.screenShare) {
                            ServerHelper.OnScreenshareToggle(false);
                        }

                        //Check if we should turn off our video share
                        if (ServerHelper.currentMode == JumbotronMode.localVideoShare) {
                            ServerHelper.OnVideoshareToggle(false);
                        }

                        //Check if we should turn off our video share
                        if (ServerHelper.currentMode == JumbotronMode.webcamShare) {
                            ServerHelper.OnPresentWebcamToggle(false);
                        }

                        //Check if we should turn off our video share
                        if (ServerHelper.currentMode == JumbotronMode.livestream) {
                            ServerHelper.OnHLSToggle(false);
                        }

                        //Check if we should turn off our video share
                        if (ServerHelper.currentMode == JumbotronMode.lsconnect) {
                            //Nothing to do here. This would be redundant to what we already started
                        }
                        break;

                }




            }

        }


        console.log("ServerHelper.currentPresenterID ServerHelper.currentPresenterID", ServerHelper.currentPresenterID);

    }

    public static async GetInitialPresenterVariable(): Promise<boolean> {
        if (ServerHelper.currentPresenterID && ServerHelper.currentPresenterID.length > 1) {

            try {
                let initialVal = await SHOWBOAT.SocketIOController.GetServerEventVariable("PRESENTER#Presentation", { mode: "off", slide: "", userID: "" });
                if (initialVal.userID) {
                    ServerHelper.currentPresenterID = initialVal.userID;
                } else {
                    ServerHelper.currentPresenterID = "";
                }
                return true;
            } catch (err) {
                console.log(err);
                ServerHelper.currentPresenterID = "";
                return true;
            }
        } else {
            return true;
        }
    }



    public static OnModeChange(mode: SHOWBOAT.ShowboatChannelType): void {

        ServerHelper.doPartitionChange(mode);

    }

    public static async doPartitionChange(mode: SHOWBOAT.ShowboatChannelType): Promise<boolean> {

        let modeString = '';
        if (mode === SHOWBOAT.ShowboatChannelType.Attendees) {
            modeString = 'attendees';
        } else if (mode === SHOWBOAT.ShowboatChannelType.Presenter) {
            modeString = 'presenter';
        } else if (mode === SHOWBOAT.ShowboatChannelType.Backstage) {
            modeString = 'backstage';
        } else {
            return false;
        }

        //Check if we need a partition change
        if (modeString == SHOWBOAT.LocalAvatarDataManager.partition) {
            return true;
        }

        try {

            //Ask socket IO to change partiions
            await SHOWBOAT.SocketIOController.ChangePartitions(modeString);

            //update my feed
            SHOWBOAT.LiveswitchUpstreamController.changeMode(mode, SHOWBOAT.LocalAvatarDataManager.eventID, SHOWBOAT.LocalAvatarDataManager.roomID);

            //update my partition
            SHOWBOAT.LocalAvatarDataManager.partition = modeString;

            //raise event
            SHOWBOAT.UIEventManager.OnPartionChange.Raise(modeString);

            console.log("NEW MODE:", modeString);

            return true;

        } catch (err) {
            console.log("Error changing partitions");
            console.log(err);
            return false;
        }


    }

    public static OnShowboatConnectToggle(isOn: boolean): void {

        console.warn("OnShowboatConnectToggle~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~");
        console.log("Is showboat connect on", isOn);

        //ALL TEMPORARY CODE TO TEST LIVESTREAM
        if (isOn) {
            ServerHelper.switchToJumbotronMode(JumbotronMode.lsconnect);
        } else {
            //Mark that our current mode is lsconnect just to ensure anyone can disable this mode even if they were not the oines who activated it
            ServerHelper.currentMode = JumbotronMode.lsconnect;
            ServerHelper.switchToJumbotronMode(JumbotronMode.off, JumbotronMode.lsconnect);
        }

    }


    public static OnSilenceAudience(audienceIsSilenced: boolean): void {
        SHOWBOAT.SocketIOController.SetServerEventVariable("PRESENTER#SilenceAudience", { muted: audienceIsSilenced });
    }

    public static OnSpatialAudioChange(spatialAudio: boolean): void {
        //When SpatialAudio is set to OFF, change zone size to 9999
        //Otherwise, set to default
        if (spatialAudio) {
            SHOWBOAT.RemotePlayersZoneConfig.Distance_Visible = SHOWBOAT.RemotePlayersZoneConfig.Default_Distance_Visible;
        } else {
            SHOWBOAT.RemotePlayersZoneConfig.Distance_Visible = 9999;
        }

        SHOWBOAT.SocketIOController.SetServerEventVariable("PRESENTER#SpatialAudio", { value: spatialAudio });
    }


    public static OnRemotePlayerDisconnected(avatarData: SHOWBOAT.AvatarData): void {
        SHOWBOAT.UIEventManager.OnLeftNearbyList.Raise(avatarData.userID);

        setTimeout(() => {
            ServerHelper.handleReconnect();     //TEMPORARY HACK TO TRY AND RECHECK FOR THE AVATAR LIST IF A ROGUE DISCONNECT HAPPENED WHILE SOMEONE IS HAVING INTERNET ISSUES
        }, 1000);


    }


    public static async ManageDeviceDebugInfo(): Promise<boolean> {
        SHOWBOAT.StreamingUserMedia.OnCameraDeviceChanged.Add(ServerHelper.OnCameraDeviceChangedDeviceDebug);
        SHOWBOAT.StreamingUserMedia.OnMicrophoneDeviceChanged.Add(ServerHelper.OnMicrophoneDeviceChangedDeviceDebug);
        SHOWBOAT.StreamingUserMedia.OnSpeakerDeviceChanged.Add(ServerHelper.OnSpeakerDeviceChangedDeviceDebug);
        SHOWBOAT.StreamingUserMedia.OnCameraStarted.Add(ServerHelper.OnCameraStartedDeviceDebug);
        SHOWBOAT.StreamingUserMedia.OnMicrophoneStarted.Add(ServerHelper.OnMicrophoneStartedDeviceDebug);


        let currentCameraName: string = ServerHelper.getCurrentCameraName();
        if (currentCameraName) {
            SHOWBOAT.LocalAvatarDataManager.currentCameraName = currentCameraName;
        }

        let currentMicrophoneName: string = ServerHelper.getCurrentMicrophoneName();
        if (currentMicrophoneName) {
            SHOWBOAT.LocalAvatarDataManager.currentMicName = currentMicrophoneName;
        }

        let currentSpeakerName: string = ServerHelper.getCurrentSpeakerName();
        if (currentSpeakerName) {
            SHOWBOAT.LocalAvatarDataManager.currentSpeakerName = currentSpeakerName;
        }

        await ServerHelper.UpdateDeviceDebugLists();
        return true;




    }

    public static OnCameraDeviceChangedDeviceDebug(deviceID: string): void {

        let cameraName: string = SHOWBOAT.SystemInformation.getCameraName(deviceID);

        if (cameraName) {
            SHOWBOAT.LocalAvatarDataManager.currentCameraName = cameraName;
            SHOWBOAT.SocketIOController.UpdatePlayerData(SHOWBOAT.LocalAvatarDataManager.avatarData, SHOWBOAT.RemoteAvatarDataManager.CHANGEREASON_DeviceDebug);
        }
        ServerHelper.UpdateDeviceDebugLists();
    }

    public static OnMicrophoneDeviceChangedDeviceDebug(deviceID: string): void {

        let microphoneName: string = SHOWBOAT.SystemInformation.getMicrophoneName(deviceID);

        if (microphoneName) {
            SHOWBOAT.LocalAvatarDataManager.currentMicName = microphoneName;
            SHOWBOAT.SocketIOController.UpdatePlayerData(SHOWBOAT.LocalAvatarDataManager.avatarData, SHOWBOAT.RemoteAvatarDataManager.CHANGEREASON_DeviceDebug);
        }
        ServerHelper.UpdateDeviceDebugLists();
    }

    public static OnSpeakerDeviceChangedDeviceDebug(deviceID: string): void {
        let speakerName: string = SHOWBOAT.SystemInformation.getSpeakerName(deviceID);
        if (speakerName) {
            SHOWBOAT.LocalAvatarDataManager.currentSpeakerName = speakerName;
            SHOWBOAT.SocketIOController.UpdatePlayerData(SHOWBOAT.LocalAvatarDataManager.avatarData, SHOWBOAT.RemoteAvatarDataManager.CHANGEREASON_DeviceDebug);
            ServerHelper.UpdateDeviceDebugLists();
        }

    }

    public static OnCameraStartedDeviceDebug(htmlElement: HTMLVideoElement): void {
        ServerHelper.UpdateDeviceDebugLists();
    }

    public static OnMicrophoneStartedDeviceDebug(): void {
        ServerHelper.UpdateDeviceDebugLists();
    }

    public static onForceMuteMic(): void {
        //SHOWBOAT.AVController.forceMuteAudio();
        SHOWBOAT.LocalAvatarDataManager.isForceMuted = true;
        SHOWBOAT.SocketIOController.UpdatePlayerData(SHOWBOAT.LocalAvatarDataManager.avatarData, SHOWBOAT.RemoteAvatarDataManager.CHANGEREASON_ForceMute);
    }

    public static onUnforceMuteMic(): void {
        //SHOWBOAT.AVController.forceMuteAudio();
        SHOWBOAT.LocalAvatarDataManager.isForceMuted = false;
        SHOWBOAT.SocketIOController.UpdatePlayerData(SHOWBOAT.LocalAvatarDataManager.avatarData, SHOWBOAT.RemoteAvatarDataManager.CHANGEREASON_ForceMute);
    }

    public static onForceMutePlayerDataUpdate(avatarData: SHOWBOAT.AvatarData): void {
        SHOWBOAT.LiveswitchDownstreamController.SetForceMute(avatarData.userID, avatarData.isForceMuted);
    }

    public static UpdateDeviceDebugLists(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            DetectRTC.load(() => {

                let notifyServer: boolean = false;

                //Current Camera
                let currentCameraName: string = ServerHelper.getCurrentCameraName();
                if (currentCameraName && currentCameraName != SHOWBOAT.LocalAvatarDataManager.currentCameraName) {
                    notifyServer = true;
                    SHOWBOAT.LocalAvatarDataManager.currentCameraName = currentCameraName;
                }

                //Current Mic
                //Current Camera
                let currentMicName: string = ServerHelper.getCurrentMicrophoneName();
                if (currentMicName && currentMicName != SHOWBOAT.LocalAvatarDataManager.currentMicName) {
                    notifyServer = true;
                    SHOWBOAT.LocalAvatarDataManager.currentMicName = currentMicName;
                }


                //CAMERA
                if (DetectRTC.videoInputDevices && DetectRTC.videoInputDevices.length > 0) {
                    let cameraDeviceList = "";
                    for (let i = 0; i < DetectRTC.videoInputDevices.length; ++i) {
                        if (i > 0) {
                            cameraDeviceList += "|";
                        }
                        cameraDeviceList += DetectRTC.videoInputDevices[i].label;
                    }
                    if (cameraDeviceList != SHOWBOAT.LocalAvatarDataManager.cameraDeviceList) {
                        SHOWBOAT.LocalAvatarDataManager.cameraDeviceList = cameraDeviceList;
                        notifyServer = true;
                    }
                }

                //MIC
                if (DetectRTC.audioInputDevices && DetectRTC.audioInputDevices.length > 0) {
                    let micDeviceList = "";
                    for (let i = 0; i < DetectRTC.audioInputDevices.length; ++i) {
                        if (i > 0) {
                            micDeviceList += "|";
                        }
                        micDeviceList += DetectRTC.audioInputDevices[i].label;
                    }
                    if (micDeviceList != SHOWBOAT.LocalAvatarDataManager.micDeviceList) {
                        SHOWBOAT.LocalAvatarDataManager.micDeviceList = micDeviceList;
                        notifyServer = true;
                    }
                }

                //SPEAKER
                if (DetectRTC.hasSpeakers) {
                    let speakerDeviceList = "";
                    for (let i = 0; i < DetectRTC.audioOutputDevices.length; ++i) {
                        if (i > 0) {
                            speakerDeviceList += "|";
                        }
                        speakerDeviceList += DetectRTC.audioOutputDevices[i].label;
                    }
                    if (speakerDeviceList != SHOWBOAT.LocalAvatarDataManager.speakerDeviceList) {
                        SHOWBOAT.LocalAvatarDataManager.speakerDeviceList = speakerDeviceList;
                        notifyServer = true;
                    }
                }


                //Notify the server of new device lists
                if (notifyServer && SHOWBOAT.SocketIOController.isConnected) {
                    SHOWBOAT.SocketIOController.UpdatePlayerData(SHOWBOAT.LocalAvatarDataManager.avatarData, SHOWBOAT.RemoteAvatarDataManager.CHANGEREASON_DeviceDebug);
                }

                resolve(true);

            });
        });
    }

    private static getCurrentCameraName(): string {
        let currentCameraDeviceID: string = SHOWBOAT.StreamingUserMedia.getCurrentCameraDevice();
        if (!currentCameraDeviceID) return undefined;
        return SHOWBOAT.SystemInformation.getCameraName(currentCameraDeviceID);
    }

    private static getCurrentMicrophoneName(): string {
        let currentMicrophoneDeviceID: string = SHOWBOAT.StreamingUserMedia.getCurrentMicrophoneDevice();
        if (!currentMicrophoneDeviceID) return undefined;
        return SHOWBOAT.SystemInformation.getMicrophoneName(currentMicrophoneDeviceID);
    }

    private static getCurrentSpeakerName(): string {
        let currentSpeakerDeviceID: string = SHOWBOAT.StreamingUserMedia.getCurrentSpeakerDevice();
        if (!currentSpeakerDeviceID) return undefined;
        return SHOWBOAT.SystemInformation.getSpeakerName(currentSpeakerDeviceID);
    }

    private static On3DAvatarLoadComplete() {
        SHOWBOAT.LocalAvatarDataManager.loadComplete = true;
        SHOWBOAT.SocketIOController.UpdatePlayerData(SHOWBOAT.LocalAvatarDataManager.avatarData, SHOWBOAT.RemoteAvatarDataManager.CHANGEREASON_LoadComplete);
    }

    //Stash intake data for login code
    public static stashIntakeDataForLoginCode(firstName, lastName, company, emailAddress) {

        let loginCode: string = ServerHelper.loginCode;
        let intakeObj: any = {
            firstName,
            lastName,
            company,
            emailAddress
        }
        //First check if we have intakeData stored in local storage
        if (localStorage.getItem("intakeData") !== null) {

            let intakeData = JSON.parse(localStorage.getItem("intakeData"));

            //Add or overwrite this intakeData associated with this loginCode
            intakeData[loginCode] = intakeObj;

            //Overwrite the master
            intakeData["master"] = intakeObj;

            localStorage.setItem("intakeData", JSON.stringify(intakeData));

        } else {
            //intakeData does not exist in localStorage, so set it up
            let intakeData: any = {};

            //Set intakeObj for this login code
            intakeData[loginCode] = intakeObj;

            //Set master
            intakeData["master"] = intakeObj;

            localStorage.setItem("intakeData", JSON.stringify(intakeData));
        }

    }
}

(window as any).ServerHelper = ServerHelper;