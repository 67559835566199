import { makeStyles, createStyles } from '@material-ui/core/styles';

const PresenterItemStyles = makeStyles((theme: any) => createStyles({
  presenterName: {
    fontSize: 16,
    fontFamily: "Roboto, sans-serif",
    fontWeight: 400,
    position: "relative",
    left: 25,
  },
  radio: {
    color: "#747474 !important",
    position: "relative",
    left: "75%"
  },
  radioChecked: {
    color: "#FF00E2 !important",
  },
  radioDisabled: {
    opacity: 0.3
  }
})
)

export { PresenterItemStyles };