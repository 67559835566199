import { makeStyles, createStyles } from '@material-ui/core/styles';

const DefaultScreenStyles = makeStyles((theme: any) => createStyles({
  showDefaultButton: {
    backgroundColor: "#777777",
    position: "relative",
    textAlign: "left",
    padding: 0,
    width: 240,
    height: 44
  }
})
)

export { DefaultScreenStyles };