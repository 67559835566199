import { Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import React, { ReactElement, useState, useEffect, useContext } from 'react';
import MicIcon from '@material-ui/icons/Mic';
import MicMuteIcon from '@material-ui/icons/MicOff';
import { ServerHelper } from '../../../../utilities/ServerHelper';
import MicTestModule from './MicTestModule/MicTestModule';
import resolutionStore from '../../../../utilities/ResolutionStore';
import fpsStore from '../../../../utilities/FPSStore';

import styles from "./ShowboatConnect.module.css";
import { ShowboatConnectStyles } from './ShowboatConnectStyles';
import { JumbotronContext } from '../../../../Context/JumbotronContext';

interface Props {

}

export default function ShowboatConnect(props: Props): ReactElement {

  const {
    setCurrentPreviewType,
    disableCamAndMicSettings,
    toggleDisableCamAndMicSettings,
    setBlankMessage,
    isLive,
    livestreamFPS,
    livestreamResolution,
    currentLiveType
  } = useContext(JumbotronContext);

  //State
  const [useMic, toggleUseMic] = useState(false);
  const [useCamera, toggleUseCamera] = useState(false);
  const [deviceError, toggleDeviceError] = useState(false);

  const [deviceErrorStore, setDeviceErrorStore] = useState([]);
  const [isTestingSpeakers, toggleIsTestingSpeakers] = useState(false);

  const [currentMic, setCurrentMic] = useState(null);
  const [currentCamera, setCurrentCamera] = useState(null);
  const [currentSpeaker, setCurrentSpeaker] = useState(undefined);

  const [currentMicList, setCurrentMicList] = useState([]);
  const [currentCameraList, setCurrentCameraList] = useState([]);
  const [currentSpeakerList, setCurrentSpeakerList] = useState([]);

  //Default FPS and Resolution to what is currently chosen for the livestream
  const [cameraResolution, setCameraResolution] = useState(livestreamResolution);
  const [cameraFPS, setCameraFPS] = useState(livestreamFPS);

  useEffect(() => {

    //TODO: TODO: TODO:
    //If devices are running, throw the video on the jumbotron. Otherwise, throw up the reset
    if (disableCamAndMicSettings) {

      ServerHelper.PreviewJumbotronBroadcastMediaStream.addMediaStream(
        SHOWBOAT.StreamingUserMedia.getCameraStream(),
        true,
        true,
        0,
        0,
        parseInt(cameraResolution.split("x")[0]),
        parseInt(cameraResolution.split("x")[1]),
      )

      //Check if mic is running 
      toggleUseMic(SHOWBOAT.StreamingUserMedia.isMicrophoneRunning());

      setBlankMessage("Please click start with desired settings");
      
      setCurrentPreviewType("sbconnect");
    } else {

      //Disable settings if we do not have cam/mic permissions
      if (!SHOWBOAT.SystemInformation.HasWebCameraPermissions) {
        toggleDisableCamAndMicSettings(true);
      }

      //Set blank message in context
      setBlankMessage("Please click start with desired settings");

      ServerHelper.PreviewJumbotronBroadcastMediaStream.clear();

      setCurrentPreviewType("blank");
    }

    //Register for devices getting plugged or unplugged
    const onDeviceChange = () => {

      //TODO:... check if our device got unplugged

      refreshDevices();
    }

    //Add an event listener for available devices changing
    SHOWBOAT.SystemInformation.OnDeviceListChanged.Add(onDeviceChange);

    //Listen for the camera starting
    const OnCameraStarted = (htmlVideoElement: HTMLVideoElement) => {

      refreshDevices().then(() => {
        //show camera button on
        toggleUseCamera(true);

        //update the current camera listed
        updateCurrentDevices(true);

        //clear any previous errors
        toggleDeviceError(false);

      }).catch(() => {

      });

    }
    SHOWBOAT.StreamingUserMedia.OnCameraStarted.Add(OnCameraStarted);

    //Listen for the mic starting
    const OnMicrophoneStarted = () => {

      refreshDevices().then(() => {
        //turn the mic button on
        toggleUseMic(true);

        //update the currently displayed mic
        updateCurrentDevices(true);

        //clear any previous errors
        toggleDeviceError(false);
      }).catch(() => {

      });

    }
    SHOWBOAT.StreamingUserMedia.OnMicrophoneStarted.Add(OnMicrophoneStarted);

    //Listen for camera stopping
    const OnCameraStopped = () => {
      toggleUseCamera(false);
      //setAudioLevel(0);
    }
    SHOWBOAT.StreamingUserMedia.OnCameraStopped.Add(OnCameraStopped);

    //Listen for microphone stopping
    const OnMicrophoneStopped = () => {
      toggleUseMic(false);
    }
    SHOWBOAT.StreamingUserMedia.OnMicrophoneStopped.Add(OnMicrophoneStopped);

    //Listen for changes to the current camera
    const OnCameraDeviceChanged = () => {
      updateCurrentCamera(SHOWBOAT.StreamingUserMedia.isCameraRunning());
    }
    SHOWBOAT.StreamingUserMedia.OnCameraDeviceChanged.Add(OnCameraDeviceChanged);


    //Listen for changes to the current mic
    const OnMicrophoneDeviceChanged = () => {
      updateCurrentMicrophone(SHOWBOAT.StreamingUserMedia.isMicrophoneRunning());
    }
    SHOWBOAT.StreamingUserMedia.OnMicrophoneDeviceChanged.Add(OnMicrophoneDeviceChanged);

    //Event listener for AV Errors
    const OnAVMediaError = (errorMsg: string, errorObject: any) => {
      toggleDeviceError(true);

      SHOWBOAT.UIEventManager.OnUIError.Raise("Error starting virtual camera.")

      setDeviceErrorStore([errorMsg]);
    }
    SHOWBOAT.StreamingUserMedia.OnAVMediaError.Add(OnAVMediaError);


    //Update our device list
    refreshDevices().then(() => {
      //Update our current device selection
      updateCurrentMicrophone();
      updateCurrentCamera();
    }).catch(() => {

    });

    return function cleanup() {
      //Remove listeners
      SHOWBOAT.StreamingUserMedia.OnCameraStarted.Remove(OnCameraStarted);
      SHOWBOAT.StreamingUserMedia.OnMicrophoneStarted.Remove(OnMicrophoneStarted);
      SHOWBOAT.StreamingUserMedia.OnCameraStopped.Remove(OnCameraStopped);
      SHOWBOAT.StreamingUserMedia.OnMicrophoneStopped.Remove(OnMicrophoneStopped);
      SHOWBOAT.StreamingUserMedia.OnCameraDeviceChanged.Remove(OnCameraDeviceChanged);
      SHOWBOAT.StreamingUserMedia.OnMicrophoneDeviceChanged.Remove(OnMicrophoneDeviceChanged);
      SHOWBOAT.StreamingUserMedia.OnAVMediaError.Remove(OnAVMediaError);
      SHOWBOAT.SystemInformation.OnDeviceListChanged.Remove(onDeviceChange);

      //Stop cam/mic on dismount
    }
  }, []);

  //Handle getting updated device lists
  const refreshDevices = async () => {
    try {

      //Check if we need to retry getting device names
      if (!SHOWBOAT.SystemInformation.HaveCameraNames || SHOWBOAT.SystemInformation.HaveMicrophoneNames) {
        await SHOWBOAT.SystemInformation.Load();
      }


      //Set the current device lists
      setCurrentMicList(SHOWBOAT.SystemInformation.AudioInputDevices);
      setCurrentCameraList(SHOWBOAT.SystemInformation.VideoInputDevices);
      setCurrentSpeakerList(SHOWBOAT.SystemInformation.AudioOutputDevices);

    } catch (e) {
      SHOWBOAT.Logger.Error(e);
    }

  }

  const doInitialSetup = async (useMic: boolean) => {

    //Try to start the camera/mic

    //Check which options the user selected before coming to this page
    //also ensure the system has the necessary device type
    let initialCameraState: boolean = SHOWBOAT.SystemInformation.HasWebCamera;
    let initialMicrophoneState: boolean = true && SHOWBOAT.SystemInformation.HasMicrophone && useMic;

    //Ensure we have a device to start
    if (initialCameraState || initialMicrophoneState) {

      console.log("Mic state", initialMicrophoneState);

      //Start the devices
      await SHOWBOAT.StreamingUserMedia.SetDeviceStates(initialCameraState, initialMicrophoneState);

      toggleUseMic(SHOWBOAT.StreamingUserMedia.isMicrophoneRunning());

      //If we are starting camera, set the resolution 
      if (initialCameraState) {
        //Set resolution on camera feed using current state
        let resArray = cameraResolution.split("x");

        let width = parseInt(resArray[0]);
        let height = parseInt(resArray[1]);

        await SHOWBOAT.StreamingUserMedia.setPreferredCameraSize(width, height);

        console.log("Starting camera with settings:", width, height, cameraFPS);

        //Set fps on camera feed using current state
        SHOWBOAT.StreamingUserMedia.setCameraFPS(cameraFPS);

        console.log("B");

        //Send video over to preview jumbotron
        ServerHelper.PreviewJumbotronBroadcastMediaStream.addMediaStream(
          SHOWBOAT.StreamingUserMedia.getCameraStream(),
          true,
          true,
          0,
          0,
          width,
          height,
        )
        setCurrentPreviewType("sbconnect");

      }

      //Update our current device selection
      updateCurrentMicrophone();
      updateCurrentCamera();

    } else {
      //We don't have any devices
      //TODO: 
      SHOWBOAT.UIEventManager.OnUIError.Raise("No camera or microphone detected");

      setDeviceErrorStore(["No camera or microphone has been detected"]);
    }

  };

  const updateCurrentDevices = (useCurrent: boolean = false) => {
    updateCurrentMicrophone(useCurrent);
    updateCurrentCamera(useCurrent);
  }

  const updateCurrentMicrophone = (useCurrent: boolean = false) => {

    console.log("A");
    let mediaDeviceID: string = useCurrent ? SHOWBOAT.StreamingUserMedia.getCurrentMicrophoneDevice() : SHOWBOAT.StreamingUserMedia.getPrefferedMicrophoneDevice();
    if (mediaDeviceID && mediaDeviceID.length > 0) {
      if (SHOWBOAT.SystemInformation.AudioInputDevices) {
        for (let i = 0; i < SHOWBOAT.SystemInformation.AudioInputDevices.length; ++i) {
          if (SHOWBOAT.SystemInformation.AudioInputDevices[i].deviceId == mediaDeviceID) {

            //Set value for preferred microphone in localStorage
            localStorage.setItem("preferredMicrophoneID", SHOWBOAT.SystemInformation.AudioInputDevices[i].deviceId);

            setCurrentMic(SHOWBOAT.SystemInformation.AudioInputDevices[i]);
            break;
          }
        }
      }
    }
  }

  const updateCurrentCamera = (useCurrent: boolean = false) => {
    let mediaDeviceID: string = useCurrent ? SHOWBOAT.StreamingUserMedia.getCurrentCameraDevice() : SHOWBOAT.StreamingUserMedia.getPrefferedCameraDevice();
    if (mediaDeviceID && mediaDeviceID.length > 0) {
      if (SHOWBOAT.SystemInformation.VideoInputDevices) {
        for (let i = 0; i < SHOWBOAT.SystemInformation.VideoInputDevices.length; ++i) {
          if (SHOWBOAT.SystemInformation.VideoInputDevices[i].deviceId == mediaDeviceID) {

            //Set initial value for camera preference in localStorage
            localStorage.setItem("preferredCameraID", SHOWBOAT.SystemInformation.VideoInputDevices[i].deviceId);

            setCurrentCamera(SHOWBOAT.SystemInformation.VideoInputDevices[i]);
            break;
          }
        }
      }
    }
  }

  const onCameraDeviceChange = (event: any) => {
    if (!event.target.value) return;
    if (currentCameraList && currentCameraList.length > 0) {
      for (let i = 0; i < currentCameraList.length; ++i) {
        if (currentCameraList[i].deviceId == event.target.value) {
          SHOWBOAT.StreamingUserMedia.SetCameraDeviceID(event.target.value);

          //Change preferred camera device in local storage
          localStorage.setItem("preferredCameraID", currentCameraList[i].deviceId)
          setCurrentCamera(currentCameraList[i]);
          break;
        };
      }
    }
  }

  const onAudioDeviceChange = (event: any) => {
    console.log("C");
    if (!event.target.value) return;
    if (currentMicList && currentMicList.length > 0) {
      for (let i = 0; i < currentMicList.length; ++i) {
        if (currentMicList[i].deviceId == event.target.value) {
          SHOWBOAT.StreamingUserMedia.SetMicrophoneDeviceID(event.target.value);

          //Set preferred microphone value in local storage
          localStorage.setItem("preferredMicrophoneID", currentMicList[i].deviceId);

          setCurrentMic(currentMicList[i]);
          break;
        }
      }
    }
  }

  const toggleMuteMic = () => {

    if (SHOWBOAT.StreamingUserMedia.isOperationInProgress()) return;

    if(SHOWBOAT.StreamingUserMedia.isCameraRunning()){
      SHOWBOAT.StreamingUserMedia.SetDeviceStates(useCamera, !useMic);
    } else {
      toggleUseMic(!useMic);
    }

    
    
  }

  //Camera resolution change handler
  const handleCameraResolutionChange = async (e) => {
    let newResolution = e.target.value;

    setCameraResolution(newResolution);

    //Change resolution of camera stream
    let resArray = (e.target.value).split("x");

    await SHOWBOAT.StreamingUserMedia.setPreferredCameraSize(resArray[0], resArray[1]);

  }

  //FPS change handler
  const handleCameraFPSChange = async (e) => {
    let newFPS = e.target.value;

    SHOWBOAT.StreamingUserMedia.setCameraFPS(newFPS);

    setCameraFPS(newFPS);
  }

  //Start button
  const handleStartButtonClick = () => {
    
    //Start cam and mic if selected to start
    doInitialSetup(useMic);

    //Disable settings
    toggleDisableCamAndMicSettings(true);
  }

  //Stop button
  const handleStopButtonClick = () => {

    toggleDisableCamAndMicSettings(false);

    //Stop devices
    SHOWBOAT.StreamingUserMedia.SetDeviceStates(false, false);

    //Set blank message
    setBlankMessage("Please click start with desired settings");

    //Show blank in preview jumbo
    ServerHelper.PreviewJumbotronBroadcastMediaStream.clear();

    setCurrentPreviewType("blank");

    //Change live to default asset if we have Virtual Camera on live jumbotron
    if (isLive && currentLiveType === "sbconnect") {
      ServerHelper.ShowDefaultAssetOnLive();

    }

  }

  //Request permissions
  const handleRequestPermissions = async () => {
    //Do nothing if we already have permissions
    if (SHOWBOAT.SystemInformation.HasMicrophonePermissions 
      && SHOWBOAT.SystemInformation.HasWebCameraPermissions) {
        return;
    }

    let permissions = await SHOWBOAT.SystemInformation.RequestCameraAndMicPermissions(true, true);

    if (permissions.success) {
      toggleDisableCamAndMicSettings(false);

      //Populate dropdown menus
      setCurrentCameraList(SHOWBOAT.SystemInformation.VideoInputDevices);
      setCurrentMicList(SHOWBOAT.SystemInformation.AudioInputDevices);
    }
  }

  //Microphone menu set-up
  let currentAudioDeviceID: string = currentMic ? currentMic.deviceId : "";
  let audioInputMenuItems: JSX.Element[] = [];

  if (currentMicList && currentMicList.length > 0 && currentMicList[0].deviceId) {
    audioInputMenuItems = currentMicList.map((mediaDevice: MediaDeviceInfo) => {
      return (
        <MenuItem key={`${mediaDevice.deviceId} ${mediaDevice.label}`} value={mediaDevice.deviceId}>{mediaDevice.label}</MenuItem>
      );
    })
  } else {
    currentAudioDeviceID = "Unknown";
    audioInputMenuItems.push(<MenuItem key={"Unknown"} value={"Unknown"}>{"No microphones found"}</MenuItem>);

  }

  //Camera menu set-up
  let currentVideoDeviceID: string = currentCamera ? currentCamera.id : "";
  let cameraInputMenuItems: JSX.Element[] = [];

  if (currentCameraList && currentCameraList.length > 0 && currentCameraList[0].deviceId) {

    cameraInputMenuItems = currentCameraList.map((mediaDevice: MediaDeviceInfo) => {
      return (
        <MenuItem key={`${mediaDevice.deviceId} ${mediaDevice.label}`} value={mediaDevice.deviceId}>{mediaDevice.label}</MenuItem>
      )
    });
  } else {
    currentVideoDeviceID = "Unknown";
    cameraInputMenuItems.push(<MenuItem key={"Unknown"} value={"Unknown"}>{"No cameras found"}</MenuItem>);
  }

  const classes = ShowboatConnectStyles();

  return (

    <div className={styles.sbConnectHolder}>

      <Typography variant="h2" className={classes.virtualCameraHeader}>
        VIRTUAL CAMERA
      </Typography>

      {!SHOWBOAT.SystemInformation.HasWebCameraPermissions &&
        <Button 
          variant="contained" 
          color="primary" 
          className={classes.permissionsButton}
          onClick={handleRequestPermissions}
        >
          REQUEST DEVICE PERMISSIONS
        </Button>
      }
      
      <Typography variant="h3" className={classes.selectCameraHeader}>
        Camera
        </Typography>

      <FormControl
        classes={{ root: classes.cameraSelectHolder }}
        disabled={disableCamAndMicSettings}
      >
        <Select
          value={currentVideoDeviceID}
          onChange={onCameraDeviceChange}
        >
          {cameraInputMenuItems}
        </Select>
      </FormControl>

      <Typography variant="h3" className={classes.resolutionHeader}>
        Resolution
      </Typography>

      <FormControl
        classes={{ root: classes.resolutionSelectHolder }}
        disabled={disableCamAndMicSettings}
      >
        <Select
          value={cameraResolution}
          onChange={handleCameraResolutionChange}
        >
          {resolutionStore.map(resolution => {
            return (
              <MenuItem key={resolution} value={resolution}>{resolution}</MenuItem>
            )
          })}
        </Select>
      </FormControl>

      <Typography variant="h3" className={classes.fpsHeader}>
        FPS
        </Typography>

      <FormControl
        classes={{ root: classes.fpsSelectHolder }}
        disabled={disableCamAndMicSettings}
      >
        <Select
          value={cameraFPS}
          onChange={handleCameraFPSChange}
        >
          {fpsStore.map(fps => {
            return (
              <MenuItem key={fps} value={fps}>{fps}</MenuItem>
            )
          })}
        </Select>
      </FormControl>

      <Typography
        variant="h2"
        className={classes.selectMicHeader}
      >
        Microphone
        </Typography>

      <FormControl
        classes={{ root: classes.micSelectHolder }}
        disabled={disableCamAndMicSettings}
      >
        <Select
          value={currentAudioDeviceID}
          onChange={onAudioDeviceChange}
        >
          {audioInputMenuItems}
        </Select>
      </FormControl>

      <Button
        variant="contained"
        classes={{ root: classes.muteMicButton }}
        color="primary"
        onClick={toggleMuteMic}
        disabled={disableCamAndMicSettings}
      >
        {useMic
          ? (
            <MicIcon classes={{ root: classes.micIcon }} />
          )
          : (
            <MicMuteIcon classes={{ root: classes.micIcon }} />
          )}
      </Button>

      <Button
        className={classes.startButton}
        variant="contained"
        onClick={handleStartButtonClick}
        disabled={disableCamAndMicSettings || (currentCamera === null)}
      >
        START
        </Button>

      <Button
        className={classes.stopButton}
        variant="contained"
        onClick={handleStopButtonClick}
        disabled={!disableCamAndMicSettings}
      >
        STOP
        </Button>

    </div>

  )
}
