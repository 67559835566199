import { makeStyles, createStyles } from '@material-ui/core/styles';

const ShowboatBridgeStyles = makeStyles((theme: any) => createStyles({
  showboatBridgeHolder: {
    width: "100%",
    height: 200,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    borderRadius: 10,
    [theme.breakpoints.down('md')]: {

    }
  },
  showboatBridgeHeader: {
    color: theme.palette.text.main,
    fontFamily: "Open Sans, sans-serif",
    fontSize: 42,
    position: 'absolute',
    right: 0,
    top: 2,
  },
  showboatBridgeItem: {
    position: "relative",
    borderRadius: 10,
    width: 260,
    height: 250,
    backgroundColor: theme.palette.background.paper,
    display: "inline-block",
    marginRight: 16,
    marginBottom: 22,
    textAlign: "center",
    [theme.breakpoints.down('md')]: {
      width: 212,
      height: 212,
    }

  },
  showboatBridgeItemStreamConnect: {
    backgroundColor: theme.palette.intakePaper.main,
    border: "2px solid #FF0092",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.bridgeItemHover.main
    }
  },
  showboatBridgeItemHeader: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 14,
    position: "absolute",
    bottom: 73,
    left: "50%",
    transform: "translateX(-50%)",
    fontWeight: 500,
    [theme.breakpoints.down('md')]: {
      bottom: 50,
    }
  },
  showboatBridgeItemIcon: {
    position: "absolute",
    bottom: 110,
    left: "50%",
    width: 65,
    height: 65,
    transform: "translateX(-50%)",
    [theme.breakpoints.down('md')]: {
      bottom: 92,
    }
  }
})
)

export { ShowboatBridgeStyles };